import mixins from "../mixins";

const state = () => ({
  ...mixins.getDefaultState(),
});

const getters = {
  ...mixins.getDefaultGetters(),
  ...mixins.getCodeGetters(),

  getExpanded: (state, getters, rootState, rootGetters) => (key) => {
    const experiment = getters.get(key);
    if (!experiment) {
      return undefined;
    }
    const study = rootGetters["study/get"](experiment.study_id),
      project = rootGetters["project/get"](study.project_id);
    return {
      ...experiment,
      study,
      project,
    };
  },

  getName: (state, getters) => (key) => {
    return getters.getPropertyValue(key, "name");
  },

  filterByStudy: (state, getters, rootState, rootGetters) => (key) => {
    const id = Number.isInteger(key) ? key : rootGetters["study/getId"](key);
    return getters.filterBy("study_id", id);
  },
};

const mutations = {
  ...mixins.getDefaultMutations(),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
