import mixins from "../mixins";

const state = () => ({
  ...mixins.getDefaultState(),
});

const getters = {
  ...mixins.getDefaultGetters(),
  ...mixins.getCodeGetters(),

  getExpanded: (state, getters, rootState, rootGetters) => (key) => {
    const study = getters.get(key);
    if (!study) {
      return undefined;
    }
    const project = rootGetters["project/get"](study.project_id);
    return {
      ...study,
      project,
    };
  },

  getName: (state, getters) => (key) => {
    return getters.getPropertyValue(key, "name");
  },

  filterByProject: (state, getters, rootState, rootGetters) => (key) => {
    const id = Number.isInteger(key) ? key : rootGetters["project/getId"](key);
    return getters.filterBy("project_id", id);
  },
};

const mutations = {
  ...mixins.getDefaultMutations(),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
