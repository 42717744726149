<template>
  <div>
    <!-- vertical layout -->
    <v-row v-if="verticalLayout" dense>
      <v-col md="1">
        {{splitCategory}}
      </v-col>
      <v-col md="11">
        <div :id="elementId" :style="heatmapStyle">
          heatmap content
        </div>
      </v-col>
    </v-row>
    <!-- horizontal layout -->
    <v-card v-else>
      <v-card-title v-if="splitCategory">
        {{splitCategory}}
      </v-card-title>
      <v-card-text>
        <div :id="elementId" :style="heatmapStyle">
          heatmap content
        </div>
      </v-card-text>
    </v-card>
    <BoxPlotDialog :title="dialogTitle" :comparison-id="dialogComparisonId" :analyte-id="dialogAnalyteId" />
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import * as echarts from "echarts";
  import BoxPlotDialog from "./BoxPlotDialog";

  export default {
    name: "Heatmap",

    components: {
      BoxPlotDialog
    },

    props: {
      /**
       * card title
       */
      splitCategory: {
        type: String,
      },
      xAxis: {
        type: Array,
        required: true
      },
      yAxis: {
        type: Array,
        required: true
      },
      heatmapData: {
        type: Array,
        required: true
      },
      comparisonIds: {
        type: Array,
        required: true
      },
      analyteIds: {
        type: Array,
        required: true
      },
      elementId: {
        type: String,
        required: true
      },
      globalMin: {
        type: Number,
        default: null
      },
      globalMax: {
        type: Number,
        default: null
      },
      useGlobalMinMax: {
        type: Boolean,
        default: false
      },
      /**
       * could be used to customize the display (hide scale, axis, etc..)
       */
      verticalLayout: {
        type: Boolean,
        default: false
      },
      /**
       * to customize display even more
       */
      isLast: {
        type: Boolean,
        required: true
      },
      outOfRangeValue: {
        type: Number,
        default: Number.MAX_SAFE_INTEGER
      },
      minUpperLimit: {
        type: Number,
        required: true
      },
      maxLowerLimit: {
        type: Number,
        required: true
      },
      rowHeight: {
        type: Number,
        required: true
      },
      refreshHeatmap: {
        type: Boolean
      },
      displayLabelsYAxis: {
        type: Boolean,
        default: true
      }
    },

    watch: {
      xAxis: function() {
        // console.log("watch x axis");
        this.refresh();
      },

      heatmapData: function() {
        // console.log("watch heatmap data");
        this.refresh();
      },

      useGlobalMinMax: function() {
        this.refresh();
      },

      refreshHeatmap: function() {
        this.refresh();
      }
    },

    data() {
      return {
        negativeColors: ['#0D47A1', '#1976D2', '#2196F3', '#64B5F6'],
        positiveColors: ['#E57373', '#F44336', '#D32F2F', '#B71C1C'],
        dialogTitle: "",
        dialogComparisonId: null,
        dialogAnalyteId: null
      }
    },

    computed: {
      ...mapGetters({
        getComparison: "comparison/getById",
        getComparisonResults: "comparison/getResults",
        getConcept: "concept/getById",
        getAnalyteName: "analyte/getName"
      }),

      minHeight() {
        if(this.show) {
          return 500;
        }

        return 0;
      },

      totalHeight() {
        return this.minHeight + (this.yAxis.length * this.rowHeight);
      },

      heatmapHeight() {
        return "min-height: " + this.totalHeight + "px; height: " + this.totalHeight + "px;";
      },

      heatmapStyle() {
        return this.heatmapHeight + "width: 95%";
      },

      gridHeight() {
        if(this.show) {
          // switch from % to px since a row has a constant height
          return this.yAxis.length * this.rowHeight;
        }

        return "100%";
      },

      /**
       * TODO use either px or % depending on container size
       */
      gridLeft: function() {
        return 120;
      },

      /**
       * TODO use either px or % depending on container size
       */
      gridRight: function() {
        return 30;
      },

      formattedXAxis() {
        if(this.verticalLayout) {
          return this.xAxis.map(label => label.replace(this.splitCategory, ""));
        }

        return this.xAxis;
      },

      scaleLimit() {
        let result = Math.max(Math.abs(this.minValue), Math.abs(this.maxValue));

        return result;
      },

      show() {
        if(this.verticalLayout && !this.isLast) {
          return false;
        }

        return true;
      },

      minValue() {
        if(this.useGlobalMinMax) {
          return this.globalMin;
        } else {
          return this.getMinOrMax(true);
        }
      },

      maxValue() {
        if(this.useGlobalMinMax) {
          return this.globalMax;
        } else {
          return this.getMinOrMax(false);
        }
      },

      /**
       * @deprecated unused
       */
      pieces() {
        let result = [];
        let min = this.minValue;
        let max = this.maxValue;
        result = this.splitColors(this.negativeColors, min, 0);
        result = result.concat(this.splitColors(this.positiveColors, 0, max));

        return result;
      },

      /**
       * constant part of the visualmap
       */
      visualMap() {
        return {
          min: -this.scaleLimit,
          max: this.scaleLimit,
          orient: 'horizontal',
          left: 'center',
          // switch from % to px otherwise visualmap would slowly move upward the more rows
          bottom: '10'
        }
      },

      /**
       * @deprecated unused
       */
      pieceWise() {
        let result = {
          type: 'piecewise',
          splitNumber: 8,
          pieces: this.pieces
        }

        result = Object.assign(result, this.visualMap);

        return result;
      },

      continuous() {
        let result = {
          show: this.show,
          type: 'continuous',
          calculable: true,
          precision: 2,
          inRange: {
            color: ['#0D47A1', '#FFFFFF', '#B71C1C']
          },
          // inRange: {
          //   color: this.colorRange
          // },
          // outOfRange: {
          //   color: '#00ff00'
          // }
        };

        result = Object.assign(result, this.visualMap);

        return result;
      },

      /**
       * reactive color range, approximately same range as min/max values
       * @deprecated
       */
      colorRange() {
        // base number of colors, ratio of negative/positive colors should be the same as min/max ratio 
        let base = 10;
        let ratio = Math.abs(this.minValue * 1.0 / this.maxValue);
        // console.log(ratio);
        // negative colors (blues)
        let result = this.generateColors([25, 25, 255], [235, 235, 255], base * ratio);
        // positive colors (reds)
        result = result.concat(this.generateColors([255, 235, 235], [255, 25, 25], base));
        
        return result;
      },
      
      options() {
        return {
          tooltip: {
            // position: 'top',
            confine: true,
            formatter: params => {
              if(Array.isArray(params.data)) {
                const xIndex = params.data[0];
                const yIndex = params.data[1];
                const comparisonId = this.comparisonIds[xIndex];
                const analyteId = this.analyteIds[yIndex];
                const comparison = this.getComparisonFor(+analyteId, comparisonId);
                const effectType = this.getConcept(comparison.effect_concept_id);
                const adjustedPValue = +comparison.adjusted_p_value;
                // console.log(comparison);

                return 'project: ' + comparison.project_name + '<br/>'
                  + 'study: ' + comparison.study_name + '<br/>' 
                  + 'analyte: ' + this.yAxis[yIndex] + '<br/>' 
                  + 'analyte name: ' + this.getAnalyteName(analyteId) + '<br/>'
                  + 'comparison: ' + params.name + '<br/>'
                  + 'adjusted p-value: ' + adjustedPValue.toFixed(2) + '<br/>'
                  + 'effect type: ' + effectType.name + '<br/>'
                  + 'value: ' + params.data[2].toFixed(2);
              }

              return 'NA';
            }
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: {
                title: "save as image"
              }
            }
          },
          aria: {
            enabled: true,
            decal: {
              show: true,
              decals: {
                symbol: 'rect',
                symbolSize: 1,
                color: 'rgba(0, 0, 0, 0.05)',
                dashArrayX: [
                  [1, 0],
                  [1, 6]
                ],
                dashArrayY: [1, 0, 6, 0],
                rotation: Math.PI / 4
              }
            }
          },
          grid: {
            show: true,
            height: this.gridHeight,
            top: '5',
            left: this.gridLeft,
            right: this.gridRight
          },
          xAxis: {
            show: this.show,
            type: 'category',
            axisLabel: {
              rotate: 80
            },
            // data: this.xAxis
            data: this.formattedXAxis
          },
          yAxis: {
            type: 'category',
            axisLabel: {
              show: this.displayLabelsYAxis
            },
            data: this.yAxis
          },
          visualMap: this.continuous,
          series: [{
            type: 'heatmap',
            data: this.heatmapData,
            itemStyle: {
              borderWidth: 1,
              borderColor: '#ffffff'
            },
            emphasis: {
              itemStyle: {
                borderWidth: 1,
                borderColor: '#000000'
              }
            }
          }]
        }
      }
    },

    methods: {
      getComparisonFor(analyteId, comparisonId) {
        const comparisons = this.getComparisonResults(analyteId);

        for(let i=0; i<comparisons.length; i++) {
          if(comparisonId == comparisons[i].comparison_id) {
            return comparisons[i];
          }
        }
      },

      getMinOrMax: function(isMin) {
        // let max = -Number.MAX_SAFE_INTEGER;
        let result = isMin ? this.minUpperLimit : this.maxLowerLimit;
        
        this.heatmapData.forEach(element => {
          if(Array.isArray(element)) {
            let value = element[2];

            if(value !== this.outOfRangeValue) {
              if(isMin) {
                result = Math.min(result, value);
              } else {
                result = Math.max(result, value);
              }
            }
          }
        });

        return result;
      },

      splitColors: function(colors, min, max) {
        let result = [];
        let range = Math.abs(max - min);
        let interval = range / (colors.length * 1.0);

        for(let i=0; i<colors.length; i++) {
          let piece = {};
          piece.min = min + (interval * i);
          piece.max = min + (interval * (i + 1));
          piece.color = colors[i];
          result.push(piece);
        }

        return result;
      },

      /**
       * linear interpolation
       * @deprecated used by unused
       */
      generateColors: function(fromColor, toColor, nbrColors) {
        let result = [];

        for(let i=0; i<nbrColors; i++) {
          let newColor = fromColor.map((color, index) => {
            let percent = i * 1.0 / nbrColors;
            let diff = (toColor[index] - fromColor[index]) * percent;
            return Math.floor(fromColor[index] + diff);
          });
          
          result.push(this.arrayToRGB(newColor));
        }

        result.push(this.arrayToRGB(toColor));

        return result;
      },

      /**
       * helper method: [r,g,b] -> rgb(r,g,b)
       */
      arrayToRGB: function(array) {
        let tmp = array.join();

        return `rgb(${tmp})`;
      },

      /**
       * in case heatmap content is not reactive
       */
      refresh: function() {
        let element = document.getElementById(this.elementId);
        let heatmapChart = echarts.init(element);
        heatmapChart.setOption(this.options);
        // so that divs and canvas are resized accordingly
        heatmapChart.resize();

        // this is where boxplot creation is determined
        heatmapChart.on("click", "series.heatmap", (params) => {
          if(this.$keycloak && this.$keycloak.authenticated) {
            //console.log(params);
            //const data = heatmapChart.getOption().series[params.seriesIndex].data;
            //console.log(data);
            const xIndex = params.data[0];
            const yIndex = params.data[1];
            console.log(xIndex + " " + yIndex);
            this.dialogComparisonId = this.comparisonIds[xIndex];
            this.dialogAnalyteId = this.analyteIds[yIndex];
            const comparison = this.getComparisonFor(+this.dialogAnalyteId, this.dialogComparisonId);
            this.dialogTitle = comparison.comparison_name;
          }
        });
      },

      /**
       * to manage heatmap visibility and refresh
       * @param {*} element 
       * @param {*} callback 
       */
      respondToVisibility(element, callback) {
        const options = {
          root: document.documentElement
        }

        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            callback(entry.intersectionRatio > 0);
          })
        }, options);

        observer.observe(element);
      }
    },

    mounted() {
      this.refresh();
      const toto = this;

      window.onresize = function() {
        toto.refresh();
      }

      this.respondToVisibility(document.getElementById(this.elementId), visible => {
        if(visible) {
          this.refresh();
        }
      });
    }
  }
</script>
