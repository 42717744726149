<template>
  <v-main>
    <v-container fluid>
      <!-- title -->
      <v-row>
        <v-col>
          <v-card flat>
            <v-card-title>
              Study
            </v-card-title>
            <v-card-text>
              {{ study.name }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- description -->
      <v-row>
        <v-col>
          <v-card flat>
            <v-card-title>
              Description
            </v-card-title>
            <v-card-text>
              <span v-html="study.description"></span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- project -->
      <v-row>
        <v-col>
          <v-card flat>
            <v-card-title>
              Project
            </v-card-title>
            <v-card-text>
              <router-link class="text-no-wrap" :to="'/project/' + study.project_id">
                {{ getProjectName(study.project_id) }}
              </router-link>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- provider -->
      <v-row v-if="study.details.data_provider">
        <v-col>
          <v-card flat>
            <v-card-title>
              Provider
            </v-card-title>
            <v-card-text>
              <p>
                {{ study.details.data_provider }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <!-- species -->
        <v-col>
          <v-card flat>
            <v-card-title> Species and strain</v-card-title>
            <v-card-text>
              <p>{{ getConceptName(study.species_concept_id) }}</p>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- hide/show -->
        <v-col class="d-flex justify-end align-center" v-if="experiments.length > 0">
          <v-btn dark @click="showExperiments = !showExperiments">
            {{ buttonLabel }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- experiments -->
      <v-row v-if="showExperiments && experiments.length > 0">
        <v-col>
          <v-card flat>
            <v-card-title> {{ experimentCount }} Experiments </v-card-title>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Experiment</th>
                      <th class="text-left">Omics</th>
                      <th class="text-left">Technology</th>
                      <th class="text-left">Tissue</th>
                      <th v-if="$keycloak.authenticated && study.is_public && showDownloads" class="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(experiment, index) in experiments" :key="index">
                      <td>
                        <router-link class="text-no-wrap" :to="'/experiment/' + experiment.id">
                          {{ experiment.code }}
                        </router-link>
                      </td>
                      <td>{{ getConceptName(experiment.omics_concept_id) }}</td>
                      <td>{{ getConceptName(experiment.technology_concept_id) }}</td>
                      <td>{{ getConceptName(experiment.specimen_concept_id) }}</td>
                      <td v-if="$keycloak.authenticated && study.is_public && showDownloads" class="text-center">
                        <v-icon @click="downloadData(experiment.id)">mdi-download</v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import util from "../helpers/utilities.js";
import { mapGetters } from "vuex";
import { BASE_API_URL } from "@/app.config";

export default {
  name: "Study",

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      BASE_API_URL,
      study: null,
      experiments: [],
      showExperiments: true,
      showDownloads: false,
    };
  },

  computed: {
    ...mapGetters({
      getConceptName: "concept/getName",
      getProjectName: "project/getName",
      getStudy: "study/getExpanded",
      getExperiments: "experiment/filterByStudy",
    }),

    experimentCount: function() {
      return this.experiments.length;
    },

    buttonLabel: function() {
      if (this.showExperiments) {
        return "Hide experiments";
      }

      return "Show experiments";
    },
  },

  methods: {
    downloadData: function(experimentId) {
      return util.loadFile("experiments/" + experimentId + "/data");
    },
  },

  created() {
    this.study = this.getStudy(this.id);
    if (this.study === undefined) {
      this.$router.push({ name: "Home" });
    }
    this.experiments = this.getExperiments(this.id);
  },
};
</script>

<style scoped></style>
