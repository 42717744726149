<template>
  <v-dialog v-model="dialog" scrollable width="650">
    <v-card>
      <v-card-title class="subtitle-1 grey lighten-4">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <box-plot
          :axis-data="boxPlotLabels"
          :data-values="boxPlotValues"
          :key="boxPlotLabels.join('_')"
          v-if="observationResults.size"
        />
        <v-alert outlined type="warning" prominent v-else>
          Observation results are not yet available for this comparison.
        </v-alert>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import BoxPlot from "./BoxPlot";

  export default {
    name: "BoxPlotDialog",

    components: {
      BoxPlot
    },

    props: {
      title: {
        type: String
      },
      comparisonId: {
        type: Number
      },
      analyteId: {
        type: Number
      }
    },

    watch: {
      title: function() {
        this.refresh();
      },
      comparisonId: function() {
        this.refresh();
      },
      analyteId: function() {
        this.refresh();
      }
    },

    data() {
      return {
        dialog: false
      }
    },

    computed: {
      ...mapGetters({
        getBiospecimenSetName: "comparison/getBiospecimenSetName",
        observationResults: "observation/getGroupedResults"
      }),

      boxPlotLabels() {
        const codes = Array.from(this.observationResults.keys());
        return codes.map((code) => {
          let name = this.getBiospecimenSetName(code);
          if (name) {
            return name.replace(/ - /g, "\n");
          }
          return code;
        });
      },

      boxPlotValues() {
        return Array.from(this.observationResults.values());
      }
    },

    methods: {
      ...mapActions({
        loadObservationResults: "observation/loadResults"
      }),

      refresh() {
        if(this.title && this.comparisonId && this.analyteId) {
          this.showObservationResults(this.comparisonId, this.analyteId);
        }
      },

      showObservationResults(comparisonId, analyteId) {
        this.loadObservationResults({
          analyte_id: analyteId,
          comparison_id: comparisonId,
        })
          .then(() => {
            this.dialog = true;
          })
          .catch((error) => {
            console.log(error);
          });
      },
    }
  }
</script>