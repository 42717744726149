import http from "@/router/http";

function formatNumber(number, fractionDigits = 5) {
  return Number.parseFloat(number).toFixed(fractionDigits);
}

function formatSciNum(number, floatLimit = 2, fractionDigits = 5){
  const myNum = Number(number)

  return myNum < Math.pow(10, -1 * floatLimit)
    ? myNum.toExponential(fractionDigits)
    : myNum.toFixed(fractionDigits);
}

function loadFile(url, params = {}) {
  return http
    .get(url, {
      params,
      responseType: "blob",
    })
    .then((response) => {
      const contentDisposition = response.headers["content-disposition"];
      if (!contentDisposition) {
        return;
      }
      const filename = contentDisposition.match(/filename="(.+)"/)[1];
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    })
    .catch((error) => console.log(error.message));
}

export default { formatNumber, loadFile, formatSciNum };
