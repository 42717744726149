<!-- entry point -->

<template>
  <v-app id="app">
    <v-app-bar app color="white">
      <NavBar />
    </v-app-bar>

    <router-view />

    <v-footer absolute app class="grey lighten-3">
      <Footer />
    </v-footer>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

export default {
  name: "App",
  components: {
    NavBar,
    Footer,
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.v-alert a,
.icon-link {
  text-decoration: none;
}
</style>
