<template>
  <v-card flat class="card-container transparent">
    <v-card-title class="justify-center">About EPDC</v-card-title>
    <v-card-text class="card-text justify-center">
      The European Platform for Diabetes and Complications (EPDC) aims to
      provide long term access to standardized data and results generated in
      <a href="https://www.imi.europa.eu/">IMI</a> and other projects on
      diabetes and complications. Currently, the platform is populated with data from
      <a href="https://www.imi.europa.eu/projects-results/project-factsheets/imidia">IMIDIA</a>,
      <a href="https://imi-rhapsody.eu/">RHAPSODY</a> and
      <a href="https://www.beat-dkd.eu/">BEAt-DKD</a> projects.
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "About"
};
</script>

<style scoped>
.card-container {
  max-width: 900px;
  margin: 40px auto;
}
.card-text {
  font-size: 1.2rem;
  line-height: 1.75;
}
</style>
