<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex justify-center">
        <v-btn-toggle 
          v-model="selectedSpeciesId" 
          group 
          mandatory 
          @change="changeSpecies()">
          <v-btn 
            v-for="(subHeatmaps, speciesId, index) in heatmaps" 
            :key="index" 
            :value="speciesId"
            text>
            {{getConceptName(speciesId)}}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-subtitle v-if="analytes.length">
            <v-container fluid>
              <v-row v-if="criteriaList.length > 0" dense>
                <!-- split by -->
                <v-col md="3">
                  <v-combobox 
                    v-model="heatmapSplitBy" 
                    :items="heatmapSplitByItems" 
                    label="Split heatmap content by" 
                    clearable
                    dense
                    @change="changeSplitBy()">
                  </v-combobox>
                  <v-expansion-panels 
                    v-if="heatmapSplitBy"
                    v-model="splitByExpensionModel" 
                    :value="0">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Options 
                        <span 
                          v-if="!splitByExpensionModel" 
                          class="text-center">
                          <v-chip 
                            v-if="verticalLayout" 
                            small
                            disabled
                            class="ma-1">
                            vertical
                          </v-chip>
                          <v-chip 
                            v-if="useGlobalMinMax" 
                            small
                            disabled
                            class="ma-1">
                            global
                          </v-chip>
                        </span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-switch 
                          v-model="verticalLayout"
                          label="Vertical layout" 
                          @change="changeLayout()">
                        </v-switch>
                        <v-checkbox 
                          v-model="useGlobalMinMax" 
                          :disabled="verticalLayout"
                          :label="`use global min/max 
                            (${globalMin.toFixed(this.nbrDecimals)}/${globalMax.toFixed(this.nbrDecimals)})`">
                        </v-checkbox>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                <!-- sort by -->
                <v-col md="4">
                  <v-row 
                    v-for="(sortByCategory, index) in heatmapSortByMultipleModel" 
                    :key="sortByCategory">
                    <v-col md="8">
                      <v-combobox
                        v-model="heatmapSortByMultipleModel[index]" 
                        :items="heatmapSortByMultipleItems" 
                        :label="getLabelForSortBy(index)" 
                        dense
                        @change="changeSortByMultiple()">
                      </v-combobox>                      
                    </v-col>
                    <!-- add -->
                    <v-col md="1">
                      <v-btn 
                        icon 
                        v-show="canAddSplitBy(index)" 
                        @click="addSplitBy()">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                    <!-- remove -->
                    <v-col md="1">
                      <v-btn 
                        icon 
                        v-show="index > 0" 
                        @click="removeSplitBy(index)">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </v-col>
                    <!-- <v-col md="1">
                      <v-btn icon>
                        <v-icon>mdi-arrow-down</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col md="1">
                      <v-btn icon>
                        <v-icon>mdi-arrow-up</v-icon>
                      </v-btn>
                    </v-col> -->
                  </v-row>
                  <v-expansion-panels 
                    v-model="sortByExpensionModel" 
                    :value="0">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Options 
                        <span 
                          v-if="!sortByExpensionModel" 
                          class="text-center">
                          <v-chip 
                            small
                            disabled
                            class="ma-1">
                            see notes
                          </v-chip>
                        </span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-alert type="info">
                          For sake of consistency, automatic sorting is applied whenever possible.
                        </v-alert>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                <!-- filter by -->
                <v-col md="3">
                  <v-combobox 
                    v-model="heatmapFilterBy" 
                    :items="heatmapFilterByItems" 
                    label="Filter heatmap content by" 
                    clearable 
                    dense
                    @change="changeFilterBy()">
                  </v-combobox>
                  <v-expansion-panels 
                    v-if="heatmapFilterBy"
                    v-model="filterByExpensionModel"
                    :value="0">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Options
                        <span 
                          v-if="!filterByExpensionModel"
                          class="text-center">
                          <v-chip 
                            v-for="value in heatmapFilterByValues"
                            :key="value"
                            small 
                            disabled
                            class="ma-1">
                            {{value}}
                          </v-chip>
                        </span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-checkbox 
                          v-for="value in heatmapFilterByValuesItems" 
                          :key="value"
                          v-model="heatmapFilterByValues" 
                          :disabled="isFilterDisabled(value)"
                          :label="value" 
                          :value="value"
                          @change="changeFilterByValue()">
                        </v-checkbox>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                <v-col md="1"></v-col>
                <!-- row height, vertical -->
                <v-col md="1">
                  <v-slider  
                    v-model="rowHeight"
                    thumb-label="always"
                    vertical
                    :min="minRowHeight" 
                    :max="maxRowHeight" 
                    @change="changeRowHeight()">
                    <template v-slot:append>
                      Row height
                    </template>
                    <template v-slot:prepend>
                      <v-tooltip bottom>
                        <template v-slot:activator="{on, attrs}">
                          <v-btn 
                            icon 
                            v-bind="attrs"
                            v-on="on"
                            @click="backDefaultRowheight()">
                            <v-icon>mdi-reload</v-icon>
                          </v-btn>
                        </template>
                        <span>reset</span>
                      </v-tooltip>
                    </template>
                  </v-slider>
                </v-col>
                <!-- <v-col md="1"></v-col> -->
              </v-row>
              <!-- row height, horizontal -->
              <v-row v-else dense>
                <v-col md="6" offset-md="3">
                  <v-slider  
                    v-model="rowHeight"
                    label="Row height"
                    thumb-label="always"
                    :min="minRowHeight" 
                    :max="maxRowHeight"
                    @change="changeRowHeight()">
                    <template v-slot:append>
                      <v-tooltip bottom>
                        <template v-slot:activator="{on, attrs}">
                          <v-btn 
                            icon 
                            v-bind="attrs"
                            v-on="on"
                            @click="backDefaultRowheight()">
                            <v-icon>mdi-reload</v-icon>
                          </v-btn>
                        </template>
                        <span>reset</span>
                      </v-tooltip>
                    </template>
                  </v-slider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-subtitle>
          <v-card-text>
            <!-- vertical -->
            <v-container 
                v-if="verticalLayout" 
                fluid>
              <v-row
                v-for="(subHeatmap, speciesId) in heatmaps"
                :key="speciesId"
                v-show="speciesId === selectedSpeciesId"
                dense 
                no-gutters>
                <v-col>
                  <v-row 
                    dense 
                    no-gutters
                    v-for="(heatmap, splitBy, index) in subHeatmap"
                    :key="splitBy">
                    <v-col>
                      <Heatmap
                        :split-category="(splitBy !== defaultSplitCategory) ? splitBy : null"
                        :global-min="globalMin"
                        :global-max="globalMax"
                        :row-height="rowHeight"
                        :displayLabelsYAxis="displayLabelsYAxis"
                        :refresh-heatmap="refreshHeatmap"
                        :min-upper-limit="minUpperLimit"
                        :max-lower-limit="maxLowerLimit"
                        :vertical-layout="verticalLayout"
                        :is-last="index === Object.keys(subHeatmap).length - 1"
                        :use-global-min-max="useGlobalMinMax"
                        :element-id="heatmap.elementID" 
                        :comparison-ids="heatmap.comparisonIds"
                        :analyte-ids="heatmap.analyteIds"
                        :x-axis="heatmap.xAxis" 
                        :y-axis="heatmap.yAxis" 
                        :heatmap-data="heatmap.heatmapData" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <!-- horizontal -->
            <v-container 
              fluid 
              v-else>
              <v-row 
                v-for="(subHeatmap, speciesId) in heatmaps"
                :key="speciesId"
                v-show="speciesId === selectedSpeciesId"
                dense 
                no-gutters>
                <v-col 
                  v-for="(heatmap, splitBy, index) in subHeatmap"
                  :key="splitBy">
                  <Heatmap
                    :split-category="(splitBy !== defaultSplitCategory) ? splitBy : null"
                    :global-min="globalMin"
                    :global-max="globalMax"
                    :row-height="rowHeight"
                    :displayLabelsYAxis="displayLabelsYAxis"
                    :refresh-heatmap="refreshHeatmap"
                    :min-upper-limit="minUpperLimit"
                    :max-lower-limit="maxLowerLimit"
                    :vertical-layout="verticalLayout"
                    :is-last="index === Object.keys(subHeatmap).length - 1"
                    :use-global-min-max="useGlobalMinMax"
                    :element-id="heatmap.elementID" 
                    :comparison-ids="heatmap.comparisonIds"
                    :analyte-ids="heatmap.analyteIds"
                    :x-axis="heatmap.xAxis" 
                    :y-axis="heatmap.yAxis" 
                    :heatmap-data="heatmap.heatmapData" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from "vuex";
  import Heatmap from './Heatmap.vue';

  export default {
    name: "Heatmaps",

    components: {
      Heatmap
    },

    props: {
      /**
       * analytes should be filtered by parent, one type of analyte per Heatmaps component
       */
      analytes: {
        type: Array,
        required: true
      },
      /**
       * one Heatmaps component per type
       */
      analyteTypeId: {
        type: Number,
        required: true
      }
    },

    data() {
      let rangeLimit = 0.6;

      return {
        heatmaps: {},
        heatmapSplitBy: null,
        heatmapSortByMultipleModel: [],
        heatmapFilterBy: null,
        heatmapFilterByValues: [],
        defaultSplitCategory: "default",
        defaultSpecies: "NA",
        /**
         * @deprecated
         */
        timepointsConstant: "timepoints",
        timepointConstant: "timepoint",
        tissueConstant: "tissues",
        useGlobalMinMax: true,
        verticalLayout: false,
        splitByExpensionModel: 0,
        sortByExpensionModel: 0,
        filterByExpensionModel: 0,
        outOfRangeValue: Number.MAX_SAFE_INTEGER,
        nbrDecimals: 2,
        refreshHeatmap: false,
        maxLowerLimit: rangeLimit,
        minUpperLimit: -rangeLimit,
        globalMin: 0,
        globalMax: 0,
        defaultRowHeight: 25,
        rowHeight: 25,
        minRowHeight: 5,
        maxRowHeight: 50,
        maxSplitBy: 3,
        comparisonIds: [],
        selectedSpeciesId: null
      }
    },

    computed: {
      ...mapGetters({
        results: "comparison/getAllResults",
        getComparisonResults: "comparison/getResults",
        getComparison: "comparison/getById",
        categoryIndex: "comparison/categoryIndex",
        getResultFilters: "comparison/getResultFilters",
        getExperiment: "experiment/getById",
        getConcept: "concept/getById",
        getName: "concept/getName"
      }),

      analyteType: function() {
        return this.getConceptName(this.analyteTypeId);
      },

      displayLabelsYAxis: function() {
        if(this.rowHeight < this.defaultRowHeight) {
          return false;
        }

        return true;
      },

      /**
       * options for the 'split by' dropdown menu
       */
      heatmapSplitByItems: function() {
        return this.criteriaList.filter((item) => item !== this.heatmapFilterBy);
      },
      
      /**
       * possible options provided by the "sort by" dropdown menus
       * not what is used by "split by", and not what is already selected in other "sort by"
       */
      heatmapSortByMultipleItems: function() {
        return this.criteriaList.filter(item => {
          return item !== this.heatmapSplitBy && !this.heatmapSortByMultipleModel.includes(item)
        });
      },

      /**
       * options for the 'filter by' dropdown menu
       */
      heatmapFilterByItems: function() {
        return this.criteriaList.filter((item) => item !== this.heatmapSplitBy);
      },

      /**
       * select specific values to filter by
       */
      heatmapFilterByValuesItems: function() {
        return Object.keys(this.resultsFilter);
      },

      resultsFilter: function() {
        let result = {};

        if(this.heatmapFilterBy) {
          result = this.getResultFilters(this.analyteTypeId)[this.heatmapFilterBy];
        }
        
        return result;
      },

      /**
       * get comparisons for the provided analytes
       * @deprecated these are comparison results
       */
      comparisons: function() {
        let results = [];

        for(let i=0; i<this.analytes.length; i++) {
          let analyteID = this.analytes[i].id;
          let comparisons = this.getComparisonResults(analyteID);
          results = results.concat(comparisons);
        }

        return results;
      },

      /**
       * strain, tissue, timepoint, diseases, etc...
       */
      criteriaList: function() {
        let result = [];
        let tmp = {};

        this.comparisons.forEach(comparison => {
          const categories = this.categoryIndex.get(comparison.comparison_id);
          
          // get possible categories for the selected species
          if(categories.species == this.selectedSpeciesId) {
            for(const category in categories) {
              // some categories are pointless here, should not be used
              if(category != "species" && category != "analyte") {
                if(!tmp[category]) {
                  tmp[category] = 0;
                }

                tmp[category]++;
              } 
            }
         }
        });

        let max = 0;
        const resultFilters = this.getResultFilters(this.analyteTypeId);

        // get only categories that are used in all comparisons
        for(const category in tmp) {
          max = Math.max(tmp[category]);
        }

        for(const category in tmp) {
          // also skip that category if there is only one choice available
          if(tmp[category] === max && Object.keys(resultFilters[category]).length > 1) {
            result.push(category);
          }
        }

        result.sort();
        // console.log(result);
        return result;
      }
    },

    watch: {
      /**
       * when a new analyte is added
       */
      results: function() {
        this.extractData(this.analytes);
      },

      criteriaList: function(value) {
        if(value && value.length > 0) {
          this.heatmapSortByMultipleModel = value.slice(0, 1);
        }
      }
    },

    methods: {
      changeSpecies: function() {
        // console.log(this.selectedSpecies);
        this.refreshHeatmap = !this.refreshHeatmap;
      },

      changeSplitBy: function() {
        // start with all
        this.heatmapSortByMultipleModel = this.criteriaList;

        // split by something -> remove that something from automatic sort bys for sake of consistency
        if(this.heatmapSplitBy) {
          this.heatmapSortByMultipleModel = this.heatmapSortByMultipleModel.filter(criteria => 
            criteria !== this.heatmapSplitBy);
        }

        // only use the first
        this.heatmapSortByMultipleModel = this.heatmapSortByMultipleModel.slice(0, 1);

        this.extractData(this.analytes);
      },

      addSplitBy: function() {
        this.heatmapSortByMultipleModel.push(this.heatmapSortByMultipleItems[0]);
      },

      removeSplitBy: function(index) {
        this.heatmapSortByMultipleModel.splice(index, 1);
      },

      canAddSplitBy: function(index) {
        if(index === this.heatmapSortByMultipleModel.length - 1 && 
          this.heatmapSortByMultipleModel[index] && 
          index < this.maxSplitBy - 1) {
          return true;
        }

        return false;
      },

      changeSortBy: function() {
        this.extractData(this.analytes);
      },

      changeSortByMultiple: function() {
        this.extractData(this.analytes);
      },

      /**
       * enable/disable all options
       */
      changeFilterBy: function() {
        // default behavior is to reset selection anyway
        this.heatmapFilterByValues = [];

        // if an item is selected, pre-select all possible values
        if(this.heatmapFilterBy) {
          this.heatmapFilterByValuesItems.forEach((item) => {
            this.heatmapFilterByValues.push(item);
          });
        }
        
        this.changeFilterByValue();
      },

      /**
       * update heatmap based on selected filter values
       */
      changeFilterByValue: function() {
        this.extractData(this.analytes);
      },

      changeRowHeight: function() {
        this.refreshHeatmap = !this.refreshHeatmap;
      },

      backDefaultRowheight: function() {
        this.rowHeight = this.defaultRowHeight;
        this.changeRowHeight();
      },

      /**
       * does all the work of extracting data, as well as splitting by
       */
      extractData: function(analytes) {
        let splitComparisons = {};
        // global structure: analyte -> comparison -> value
        let indexedValues = {};
        let globalMin = this.minUpperLimit;
        let globalMax = this.maxLowerLimit;

        // parse analytes to create data for heatmap(s)
        for(let i=0; i<analytes.length; i++) {
          let analyte = analytes[i];
          indexedValues[analyte.id] = {};
          let comparisonResults = this.getComparisonResults(analyte.id);

          if(comparisonResults && comparisonResults.length) {
            for(let j=0; j<comparisonResults.length; j++) {
              const comparisonResult = comparisonResults[j];
              const comparisonId = comparisonResult.comparison_id;
              let comparisonUniqueName = this.createUniqueComparisonName(comparisonResult);
              let value = comparisonResult.effect_size;
              globalMin = this.getMin(globalMin, value);
              globalMax = this.getMax(globalMax, value);
              indexedValues[analyte.id][comparisonUniqueName] = value;

              // find species
              let speciesId = 0;

              if(this.categoryIndex.get(comparisonId).species) {
                speciesId = this.categoryIndex.get(comparisonId).species;
              }

              // init species category
              if(!splitComparisons[speciesId]) {
                splitComparisons[speciesId] = {};
              }

              // find category
              let splitByCategory = this.defaultSplitCategory;
              // only for displayed species
              if(this.heatmapSplitBy && this.selectedSpeciesId == speciesId) {
                let splitByCategoryId = this.categoryIndex.get(comparisonId)[this.heatmapSplitBy];
                
                if(this.heatmapSplitBy === this.timepointConstant) {
                  splitByCategory = splitByCategoryId; 
                } else {
                  splitByCategory = this.getConcept(+splitByCategoryId).name;
                }
              }

              // init category
              if(!splitComparisons[speciesId][splitByCategory]) {
                splitComparisons[speciesId][splitByCategory] = {};
                splitComparisons[speciesId][splitByCategory].comparisons = {};
                splitComparisons[speciesId][splitByCategory].analyteIds = {};
              }

              // put comparison into the right category
              splitComparisons[speciesId][splitByCategory].comparisons[comparisonId] = comparisonResult;
              splitComparisons[speciesId][splitByCategory].analyteIds[analyte.id] = analyte.symbol;
            }
          }
        }

        // use same value for both min and max, so that 0 is at the center of the scale
        let globalLimit = Math.max(Math.abs(globalMin), Math.abs(globalMax));
        this.globalMin = -globalLimit;
        this.globalMax = globalLimit;

        // once all analytes have been parsed
        this.heatmaps = this.createHeatmaps(splitComparisons, indexedValues);
      },
      
      /**
       * sort categories, using one or several of their properties
       */
      sortContentByMultiple: function(xAxisCategories, filterItems) {
        if(xAxisCategories && xAxisCategories.length > 0) {
          if(!Array.isArray(filterItems)) {
            throw "cannot filter, argument is not an array";
          }

          if(filterItems.length > 0) {
            let sorted = xAxisCategories.sort((value1, value2) => {
              let compare = this.compareObject(value1, value2, filterItems[0]);

              // TODO find a better way to manage multiple filters (recursive?)
              if(compare === 0 && filterItems.length > 1) {
                compare = this.compareObject(value1, value2, filterItems[1]);

                if(compare === 0 && filterItems.length > 2) {
                  compare = this.compareObject(value1, value2, filterItems[2]);
                }
              }

              return compare;
            });

           return sorted;
          }

          return xAxisCategories;
        }

        return [];
      },

      /**
       * compares 2 objects (using an element from these objects) alphabetically
       */
      compareObject: function(object1, object2, tokenType) {
        let tokenId1 = this.categoryIndex.get(object1.comparison_id)[tokenType];
        let tokenId2 = this.categoryIndex.get(object2.comparison_id)[tokenType];
        let token1 = this.getName(tokenId1);
        let token2 = this.getName(tokenId2);

        if(token1 === null || token1 === undefined) {
          if(token2 === null || token2 === undefined) {
            return 0;
          }

          return 1;
        }

        return token1.localeCompare(token2);
      },

      /**
       * keep categories that match one or several values
       * result order is the same as input order
       */
      filterContentBy: function(xAxisCategories, filterByItem, selectedValues) {
        if(filterByItem) {
          let result = [];

          selectedValues.forEach(selectedValue => {
            // only keep comparisons with those comparisonIds
            let comparisonIds = this.resultsFilter[selectedValue];
            // loop and keep comparisons
            xAxisCategories.forEach(comparisonResult => {
              if(comparisonIds.includes(comparisonResult.comparison_id)) {
                result.push(comparisonResult);
              }
            });
          });
          
          return result;
        }

        return xAxisCategories;
      },

      /**
       * includes sort by/filter by
       */
      createHeatmaps: function(data, indexedValues) {
        let heatmaps = {};
        
        // species
        const species = Object.keys(data);

        species.forEach(speciesId => {
          const splitData = data[speciesId];
          heatmaps[speciesId] = {};    

          // must habe a predictable order for heatmap creation
          let sortedCategories = Object.keys(splitData);

          // if split by -> must sort between heatmaps
          if(this.heatmapSplitBy) {
            sortedCategories.sort();
          }
          
          // all unique analyte symbols for that category
          let allAnalyteSymbols = [];
          let allAnalyteIds = [];

          sortedCategories.forEach(splitCategory => {
            const analyteWrapper = splitData[splitCategory].analyteIds;
            Object.keys(analyteWrapper).forEach(key => {
              // transtype into number since it was a string (when used as a key)
              allAnalyteIds.push(+key);
              allAnalyteSymbols.push(analyteWrapper[key]);
            });
          });

          // each of these will generate a heatmap
          sortedCategories.forEach(splitCategory => {
            let splitComparison = splitData[splitCategory];
            let comparisons = Object.values(splitComparison.comparisons);
            
            // filter content
            if(this.heatmapFilterBy) {
              comparisons = this.filterContentBy(comparisons, this.heatmapFilterBy, 
                this.heatmapFilterByValues);
            }

            // sort by multiple criteria
            comparisons = this.sortContentByMultiple(comparisons, this.heatmapSortByMultipleModel);
            const comparisonNames = this.getComparisonNames(comparisons);
            const comparisonIds = comparisons.map(comparison => comparison.comparison_id);

            heatmaps[speciesId][splitCategory] = {};    
            heatmaps[speciesId][splitCategory].elementID = "heatmap_" + 
              speciesId + "_" + 
              this.analyteType + "_" + 
              splitCategory;
            heatmaps[speciesId][splitCategory].comparisonIds = comparisonIds;
            heatmaps[speciesId][splitCategory].analyteIds = allAnalyteIds;
            heatmaps[speciesId][splitCategory].xAxis = comparisonNames;
            heatmaps[speciesId][splitCategory].yAxis = allAnalyteSymbols;
            heatmaps[speciesId][splitCategory].heatmapData = this.formatValues(allAnalyteIds, 
              comparisonNames, indexedValues);
          });
        });

        return heatmaps;
      },

      getComparisonNames: function(comparisons) {
        return comparisons.map(comparison => this.createUniqueComparisonName(comparison));
      },

      /**
       * mix axis and values data to return heatmap-formatted data,
       * this is where the order is determined
       */
      formatValues: function(analyteSymbols, comparisonNames, indexedValues) {
        let values = [];

        for(let i=0; i<analyteSymbols.length; i++) {
          for(let j=0; j<comparisonNames.length; j++) {
            let analyteSymbol = analyteSymbols[i];
            let comparisonName = comparisonNames[j];
            
            // only push real values into heatmap, otherwise make them transparent
            if(isNaN(indexedValues[analyteSymbol][comparisonName])) {
              values.push({ value: [j, i, this.outOfRangeValue], itemStyle: { opacity: 0 } });
            } else {
              let value = +indexedValues[analyteSymbol][comparisonName];
              values.push([j, i, value]);
            }
          }
        }

        return values;
      },

      /**
       * concatenate tokens otherwise comparison name is not enough
       */
      createUniqueComparisonName: function(comparison) {
        return comparison.comparison_name + " [" + this.getTissue(comparison) + "]";
      },

      /**
       * get from the store instead of parsing comparison name
       */
      getTissue: function(comparison) {
          let experiment = this.getExperiment(comparison.experiment_id);
          let concept = this.getConcept(experiment.specimen_concept_id);

          return concept.name;
      },

      /**
       * skips out of range value
       */
      getMin: function(min, value) {
        if(value === this.outOfRangeValue) {
          return min;
        }

        return Math.min(min, value); 
      },

      /**
       * skips out of range value
       */
      getMax: function(max, value) {
        if(value === this.outOfRangeValue) {
          return max;
        }

        return Math.max(max, value); 
      },

      changeLayout: function() {
        if(this.verticalLayout) {
          this.useGlobalMinMax = true;
        }
      },

      getLabelForSortBy: function(index) {
        let result;

        if(index === 0) {
          result = "Sort heatmap content by";
        } else {
          result = "then sort by";
        }

        return result;
      },

      /**
       * don't disable last choice enabled
       */
      isFilterDisabled: function(value) {
        if(this.heatmapFilterByValues.length === 1 && this.heatmapFilterByValues.includes(value)) {
          return true;
        }

        return false;
      },

      getConceptName: function(conceptId) {
        return this.getConcept(+conceptId).name;
      }
    },
        
    mounted() {
      this.extractData(this.analytes);
    }
  }
</script>

<style scoped>
  .invisible {
    visibility: hidden;
  }
</style>