import http from "@/router/http";

const actions = {
  loadCoreData({ commit, dispatch }) {
    return http
      .get("datasets/core")
      .then((response) => response.data)
      .then((payload) => payload.data)
      .then((dataset) => {
        commit("vocabulary/insert", dataset.vocabularies);
        commit("domain/insert", dataset.domains);
        commit("concept/insert", dataset.concepts);
        commit("project/insert", dataset.projects);
        commit("study/insert", dataset.studies);
        commit("experiment/insert", dataset.experiments);
        commit("comparison/insert", dataset.comparisons);
        //commit('comparison/updateAllNames');
      })
      .then(() => {
        dispatch("comparison/updateNames");
      });
  },
};

export default actions;
