<template>
	<v-main>
		<v-container>
			<v-row>
				<v-col
					offset-md="3"
					md="6">
					<v-alert
						v-if="!$keycloak.authenticated"
						type="warning">
						<p>You are now logged out</p>
						<v-btn
							class="text-none"
							style="float: right"
							@click="login()">
							Sign in
						</v-btn>
					</v-alert>
					<v-alert v-else type="info">
						<p>You are still logged in</p>
						<v-btn
							class="text-none"
							style="float: right"
							@click="$keycloak.logoutFn">
							Sign out
						</v-btn>
					</v-alert>
				</v-col>
			</v-row>
		</v-container>
	</v-main>
</template>

<script>
	export default {
		name: "LoggedOut",

		methods: {
			/**
			 * redirect to home page after login
			 */
			login() {
				this.$keycloak.loginFn({redirectUri: window.location.origin});
			}
		}
	}
</script>

<style scoped>

</style>