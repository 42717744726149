<!-- home page with most of the features -->

<template>
  <v-main>
    <v-container fluid>
      <!-- search -->
      <v-row>
        <v-col>
          <SearchBar />
        </v-col>
      </v-row>
      <!-- metrics -->
      <v-row class="grey lighten-4">
        <v-col>
          <Metrics />
        </v-col>
      </v-row>
      <!-- about -->
      <v-row>
        <v-col>
          <About />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import SearchBar from "../components/SearchBar";
import Metrics from "../components/Metrics";
import About from "../components/About";

export default {
  name: "Home",

  components: {
    About,
    Metrics,
    SearchBar,
  }
};
</script>
