<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <div class="overline">
          <span>
            {{ title }}
          </span>
          <div class="actions">
            <v-icon small v-on:click.prevent="unselect()" v-if="hasSelection">mdi-close</v-icon>
          </div>
        </div>

        <v-select
          v-model="selected"
          :items="unselectedItems"
          item-text="name"
          item-value="id"
          return-object
          dense
          @change="select"
        >
        </v-select>

        <ul class="selection-list">
          <li v-for="(item, index) in selectedItems" :key="index">
            <span>
              {{ item.name }}
            </span>
            <div class="actions">
              <v-icon small v-on:click.prevent="unselect(index)">mdi-close</v-icon>
            </div>
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "SelectionList",

  data() {
    return {
      selected: null,
      selectedItems: [],
    };
  },

  props: {
    options: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "Select",
    },
  },

  computed: {
    unselectedItems() {
      return this.options.filter((option) => !this.selectedItems.includes(option));
    },
    hasSelection() {
      return this.selectedItems.length > 0;
    },
  },

  methods: {
    select(item) {
      this.selectedItems.push(item);
      this.$emit("change", this.selectedItems);
    },
    unselect(index) {
      if (index === undefined) {
        this.selectedItems = [];
      } else {
        this.selectedItems.splice(index, 1);
      }
      this.$emit("change", this.selectedItems);
      this.$nextTick(() => {
        this.selected = null;
      });
    },
    clear() {
      this.selectedItems = [];
      this.$nextTick(() => {
        this.selected = null;
      });
    },
  },
};
</script>

<style scoped>
.v-card {
  margin-bottom: 15px;
}

.selection-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.selection-list li {
  padding: 5px 0;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.selection-list li:last-child {
  border-bottom: none;
}

.actions {
  float: right;
  color: rgba(0, 0, 0, 0.26);
  cursor: pointer;
}

.actions .v-icon {
  margin: -2px -2px 0 0;
}
</style>
