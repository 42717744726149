<!-- test user roles -->

<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>Test credentials</v-card-title>
            <v-card-text>
              <pre v-if="$keycloak.tokenParsed">
								{{ JSON.stringify($keycloak.tokenParsed, null, "\t") }}
							</pre
              >
              <span v-else>not logged in</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <!-- not logged -->
        <v-col>
          <v-card class="green">
            <v-card-title>not logged in</v-card-title>
          </v-card>
        </v-col>
        <!-- guest -->
        <v-col>
          <v-card class="yellow" v-role="'guest'">
            <v-card-title>guest</v-card-title>
          </v-card>
        </v-col>
        <!-- editor -->
        <v-col>
          <v-card class="orange" v-role="'editor'">
            <v-card-title>editor</v-card-title>
          </v-card>
        </v-col>
        <!-- admin -->
        <v-col>
          <v-card class="red" v-role="'admin'">
            <v-card-title>admin</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import Vue from "vue";

export default {
  name: "Credentials",

  components: {},

  /**
   * test directive for role restriction
   */
  directives: {
    role: {
      bind: function(el, binding) {
        el.style.display = "none";
        let role = binding.value;

        if (
          Vue.prototype.$keycloak &&
          Vue.prototype.$keycloak.tokenParsed &&
          Vue.prototype.$keycloak.tokenParsed.realm_access &&
          Vue.prototype.$keycloak.tokenParsed.realm_access.roles &&
          Vue.prototype.$keycloak.tokenParsed.realm_access.roles.includes(role)
        ) {
          el.style.display = "inherit";
        }
      },
    },
  },
};
</script>

<style scoped></style>
