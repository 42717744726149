import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import keycloakConfig from "./keycloak/keycloak.json";

Vue.config.productionTip = false;

Vue.use(VueKeyCloak, {
  init: {
    // Use 'login-required' to always require authentication
    // If using 'login-required', there is no need for the router guards in router.js
    onLoad: "check-sso",
    checkLoginIframe: false,
  },
  config: keycloakConfig,
  onReady: (keycloak) => {
    store.dispatch("loadCoreData").then(() => {
      keycloak.onTokenExpired = function() {
        console.log("Keycloak token has expired");
        router.push("loggedout");
      };
      new Vue({
        vuetify,
        router,
        store,
        render: (h) => h(App),
      }).$mount("#app");
    });
  },
  onInitError: () => {
    console.log("Keycloak initialization error");
  },
});
