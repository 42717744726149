<!-- list of loaded analytes -->

<template>
  <div>
    <h6 class="section-title text-h6">
      Analytes
      <v-icon class="action-icon" v-on:click.prevent="removeAllAnalytes()" v-if="analytes.length" small
        >mdi-close</v-icon
      >
      <v-tooltip
            v-if="analytes.length"
            top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon 
            class="action-icon" 
            v-bind="attrs"
            v-on="on"
            @click="copyAnalytesToClipboard()"
            small>
            mdi-content-copy
          </v-icon>
        </template>
        <span>
          Copy analyte symbols to clipboard
        </span>
      </v-tooltip>
    </h6>
    <v-container id="analytes" fluid>
      <v-card outlined class="analytes-card">
        <v-card-text class="analytes-card-text">
          <ul class="selection-list">
            <li
              v-for="analyte in sortedAnalytes"
              :key="analyte.id"
              class="analyte"
              v-on:click.prevent="setActiveAnalyteId(analyte.id)"
            >
              <span v-bind:class="analyteClass(analyte.id)">
                {{ analyte.symbol || analyte.code }}
              </span>
              <div class="actions">
                <v-icon small v-on:click.stop.prevent="removeAnalyte(analyte.id)">mdi-close</v-icon>
              </div>
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-container>

    <v-snackbar
      v-model="snackbar"       
      :color="snackbarColor"
    >
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Analytes",

  data() {
    return {
      snackbar: false,
      clipboardStatus: false,
      snackBarSuccess: "Content copied to clipboard",
      snackbarFailure: "Could not write to clipboard"
    }
  },

  computed: {
    ...mapGetters({
      analytes: "analyte/all",
      getActiveAnalyteId: "analyte/getActiveId",
    }),

    sortedAnalytes() {
      if(this.analytes) {
        return [...this.analytes].sort(this.compareAnalytes);
      }

      return [];
    },

    /**
     * returns something compatible with the input of SearchBar component (search list variant)
     */
    analytesSymbols() {
      if(this.analytes) {
        const symbols = this.analytes.map((analyte) => {
          return analyte.symbol || analyte.code;
        });
        return symbols.join(",");
      }

      return "";
    },

    snackbarMessage() {
      if(this.clipboardStatus) {
        return this.snackBarSuccess;
      } else {
        return this.snackbarFailure;
      }
    },

    snackbarColor() {
      if(this.clipboardStatus) {
        return "info";
      } else {
        return "error";
      }
    }
  },

  watch: {
    analytes: function() {
      // replace session analytes with store analytes
      sessionStorage.setItem("analytesSymbols", this.analytesSymbols);
    }
  },

  methods: {
    ...mapActions({
      setActiveAnalyteId: "analyte/setActiveId",
      removeAnalyte: "analyte/remove",
      removeAllAnalytes: "analyte/removeAll",
    }),

    analyteClass: function(analyteId) {
      return {
        "font-weight-bold": analyteId === this.getActiveAnalyteId,
      };
    },

    /**
     * does what it says
     */
    copyAnalytesToClipboard: function(){
      // code below was to use routing to send analytes to Results, could be used later ?
      // const args = "Aldob,DPM1";
      // const route = this.$router.resolve({ path: "/results/" + args });
      // window.open(route.href);
      navigator.clipboard.writeText(this.analytesSymbols)
        .then(() => {
          this.clipboardStatus = true;
          this.snackbar = true;
        })
        .catch(() => {
          this.clipboardStatus = false;
          this.snackbar = true;
        });
    },

    compareAnalytes: function(a, b) {
      const aToken = (a.symbol || a.code);
      const bToken = (b.symbol || b.code);

      if(aToken < bToken) {
        return -1;
      } else if(aToken > bToken) {
        return 1;
      }

      return 0;
    }
  }
};
</script>

<style lang="scss" scoped>
#analytes {
  padding: 0;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.87);
}

.sel-analyte {
  font-weight: bold;
}

.analyte {
  cursor: pointer;
}

.analytes-card {
  padding-top: 8px;
  height: 200px;
  overflow-y: auto;
}

.analytes-card-text {
  padding-top: 0px;
}

.selection-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.selection-list li {
  padding: 5px 0;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.selection-list li:last-child {
  border-bottom: none;
}

.actions {
  float: right;
  color: rgba(0, 0, 0, 0.26);
  cursor: pointer;
}

.actions .v-icon {
  margin: -2px -2px 0 0;
}

.section-title {
  padding: 5px 0;
  .action-icon {
    float: right;
    margin: 10px 14px 0 0;
  }
}
</style>
