<template>
  <v-container fluid class="pa-0">
    <v-row>
      <!-- copyright -->
      <v-col md="2">
        <v-card flat class="transparent">
          <v-card-title>EPDC</v-card-title>
          <v-card-subtitle class="d-flex justify-start"
            >&copy;2020 - 2023</v-card-subtitle
          >
        </v-card>
      </v-col>
      <!-- logos -->
      <v-col md="10" class="d-flex justify-center align-center">
        <v-container fluid>
          <v-row>
            <v-col 
              v-for="(logo, index) in logos" 
              :key="index" 
              cols="2"
              class="d-flex justify-center align-center mx-1">
              <v-img 
                :src="`images/logos/${logo}`"
                contain
                :max-height="maxHeight">
              </v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "Footer",

  data: () => ({
    logos: [
      'IMI.png',
      'EFPIA.png',
      'CH.png',
      'SIB.png',
      'EU.png',
    ],
    maxHeight: 50
  }),
};
</script>

<style scoped></style>
