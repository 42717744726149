<template>
  <v-app>
    <v-app-bar app color="white">
      <NavBar />
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col md="6">
            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>
										<v-img
											src="rhapsody_logo.png" 
											max-height="100" 
											contain>
										</v-img>
									</v-card-title>
                  <v-card-text>
                    <p>
											The RHAPSODY Federated Database is a platform that allows researchers to perform remote statistical 
										analysis and data mining on multiple clinical cohorts for type 2 diabetes without sensitive data 
										disclosure. The database currently consists of 12 clinical cohort nodes linked together through a 
										central server hosted at the SIB Swiss Institute of Bioinformatics. Access to the system is via the 
										R statistical programming environment using DataSHIELD 
										(<a href="https://www.datashield.org/" target="_blank">https://www.datashield.org/</a>) 
										and dsSwissKnife 
										(<a href="https://doi.org/10.1101/2020.11.17.386813" target="_blank">https://doi.org/10.1101/2020.11.17.386813</a>) 
										software packages.
										</p>
										<p>
											This presentation page shows summary metadata for the cohorts currently available through the platform.
										</p>
										<p>
											To apply for access to the database, please contact us at 
											<a href="mailto:epdc@sib.swiss">epdc@sib.swiss</a>
										</p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <!-- file selection -->
            <v-row>
              <v-col md="7">
                <v-card>
                  <v-card-title>Datasets</v-card-title>
                  <v-card-subtitle v-if="!displayOptions">
                    Please select a dataset
                  </v-card-subtitle>
                  <v-card-text>
                    <v-list>
                      <v-list-item-group
                          v-model="selectedDataset"
                          @change="datasetChange()">
                        <v-list-item
                            v-for="(dataset, i) in datasets"
                            :key="i">
                          <v-list-item-icon>
                            <v-icon>{{dataset.icon}}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>{{dataset.text}}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-avatar>
                            <v-icon v-if="loading && i === selectedDataset">mdi-timer-sand</v-icon>
                          </v-list-item-avatar>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    <v-slider
                        v-if="displayOptions"
                        v-model="nbrTopEntries"
                        label="top entries limit"
                        :min="sliderTopMin"
                        :max="sliderTopMax"
                        @change="changeTopEntries()">
                      <template v-slot:append>
                        <v-text-field
                            v-model.number="nbrTopEntries"
                            type="number"
                            class="mt-0 pt-0"
                            style="width: 80px"
                            :rules="[requiredRule, numberRule]"
                            @input="changeTopEntries()">
                        </v-text-field>
                      </template>
                    </v-slider>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <!-- barplot -->
          <v-col md="6">
            <v-card>
              <v-card-title>
                Patients per cohort
              </v-card-title>
              <v-card-text>
                <div
                    id="barchartElement"
                    class="barChart">
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- table and heatmap -->
        <v-row>
          <!-- table -->
          <v-col md="5">
            <v-card>
              <v-card-title>
                Table
              </v-card-title>
              <v-card-subtitle>
                <v-container v-if="displayData">
                  <v-row>
                    <v-col md="10">
                      <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          clearable
                          hide-details>
                      </v-text-field>
                    </v-col>
                    <v-col md="2" class="d-flex align-end">
                      <v-btn
                          class="text-none"
                          @click="filterHeatmap(search)">
                        apply >
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
                <p v-else>
                  waiting for file
                </p>
              </v-card-subtitle>
              <v-card-text>
                <v-data-table
                    v-if="displayData"
                    :headers="tableHeaders"
                    :items="tableData"
                    :search="search"
                    dense
                    :loading="loading"
                    loading-text="loading..."
                    :items-per-page="15">
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- heatmap -->
          <v-col md="7">
            <v-card>
              <v-card-title>
                Heatmap
              </v-card-title>
              <v-card-subtitle v-if="!heatmapData.length">
                waiting for file <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
              </v-card-subtitle>
              <v-card-text>
                <div
                    :id="heatmapElementID"
                    class="heatmapChart">
                </div>
              </v-card-text>
              <v-card-actions>
                <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
	// import { use } from "echarts/core";
	// import { CanvasRenderer } from "echarts/renderers";
	// import { PieChart,HeatmapChart } from "echarts/charts";
	// import {
	// 	TitleComponent,
	// 	TooltipComponent,
	// 	LegendComponent
	// } from "echarts/components";

  import axios from "axios";
	import * as echarts from "echarts";
  import * as Papa from "papaparse";
  import NavBar from "../components/NavBar";

	// use([
	// 	CanvasRenderer,
	// 	PieChart,
	// 	HeatmapChart,
	// 	TitleComponent,
	// 	TooltipComponent,
	// 	LegendComponent
	// ]);

	export default {
		name: 'Home',

		components: {
      NavBar
		},

		watch: {
			// cohorts: function() {
			// 	this.refreshHeatmap();
			// },
			// variables: function() {
			// 	this.refreshHeatmap();
			// },
			formattedHeatmapData: function() {
				this.refreshHeatmap();
			}
		},

		data() {
			return {
				separator: ',',
				loading: false,
				displayOptions: false,
				nbrTopEntries: null,
				search: '',
				heatmapElementID: 'heatmapElement',
				clinicalType: 'clinical tests',
				medicationType: 'medication',
				vitalSignType: 'vital signs',
				clinicalFile: 'Rhapsody_clinical_tests_all.csv',
				medicationFile: 'Rhapsody_medication_all.csv',
				vitalSignFile: 'Rhapsody_vital_signs_all.csv',
				selectedDataset: null,
				selectedType: null,
				headers: [],
				rawTableData: [],
				rawHeatmapData: [],
				variables: [],
				originalVariables: [],
				cohorts: [],
				formattedHeatmapData: [],
				sliderTopMin: 0,
				sliderTopMax: 0,
				min: 0,
				max: 0,
				barplotData: [],
				barplotXAxis: [],
				requiredRule: value => !!value || 'Required.',
				numberRule: v  => {
					// if (!v.trim()) return true;
					if (!isNaN(parseFloat(v)) && v >= this.sliderTopMin && v <= this.sliderTopMax) return true;
					return 'Number has to be between ' + this.sliderTopMin + ' and ' + this.sliderTopMax;
				}
			}
		},

		computed: {
			displayData: function() {
				if(this.selectedDataset != null) {
					return true;
				}

				return false;
			},
			datasets: function() {
				return [
					{ text: this.clinicalType, icon: 'mdi-needle' },
					{ text: this.medicationType, icon: 'mdi-pill' },
					{ text: this.vitalSignType, icon: 'mdi-heart-pulse' }
				]
			},
			tableHeaders: function() {
				if(this.headers.length) {
					return this.headers;
				}

				return [];
			},
			tableData: function() {
				if(this.rawTableData.length) {
					return this.rawTableData;
				}

				return [];
			},
			/**
			 * @deprecated not reactive enough -> use watch instead
			 */
			heatmapXAxis: function() {
				if(this.cohorts.length) {
					return this.cohorts;
				}

				return [];
			},
			/**
			 * @deprecated not reactive enough -> use watch instead
			 */
			heatmapYAxis: function() {
				if(this.variables.length) {
					return this.variables;
				}

				return [];
			},
			/**
			 * @deprecated not reactive enough -> use watch instead
			 */
			heatmapData: function() {
				if(this.formattedHeatmapData.length) {
					return this.formattedHeatmapData;
				}

				return [];
			},
			heatmapMin: function() {
				if(this.min) {
					return this.min;
				}

				return 0;
			},
			heatmapMax: function() {
				if(this.max) {
					return this.max;
				}

				return 0;
			},
			heatmapOptions: function() {
				return {
					// title: {
					// 	text: 'A heatmap'
					// },
					grid: {
						height: '75%',
						top: '5%',
						left: 250,
						show: true
					},
					toolbox: {
						show: true,
						feature: {
							saveAsImage: {
								title: "save as image"
							}
						}
					},
					xAxis: {
						type: 'category',
						axisLabel: {
							rotate: 45
						},
						// data: this.heatmapXAxis,
						data: this.cohorts
					},
					yAxis: {
						type: 'category',
						// data: this.heatmapYAxis,
						data: this.variables
					},
					visualMap: {
						min: this.heatmapMin,
						max: this.heatmapMax,
						calculable: true,
						orient: 'horizontal',
						left: 'center',
						bottom: '5%'
					},
					series: [{
						type: 'heatmap',
						// data: this.heatmapData,
						data: this.formattedHeatmapData
					}],
					tooltip: {
						formatter: (param) => {
							let cohort = param.name;
							// let variable = this.heatmapYAxis[param.data[1]];
							let variable = this.variables[param.data[1]];
							let value = param.data[2];
							return cohort + ' / ' + variable + ' : ' + value;
						}
					}
				}
			},
			barplotOptions: function() {
				return {
					grid: {
						show: true,
            top: '5%',
            left: 90
					},
					xAxis: {
						type: 'category',
						name: 'cohorts',
						nameLocation: 'middle',
						nameGap: 40,
						axisLabel: {
							rotate: 45
						},
						data: this.barplotXAxis
					},
					yAxis: {
						type: 'value',
						name: 'patients',
						nameGap: 70,
						nameLocation: 'middle'
					},
					series: [{
						type: 'bar',
						coordinateSystem: 'cartesian2d',
						data: this.barplotData
					}],
					tooltip: {
						trigger: 'item'
					}
				}
			}
		},

		methods: {
			/**
			 * parse data extracted from file
			 */
			parseData: function(rows, fileType, maxNbrEntries) {
				let data = {};
				let variables = {};
				let variableIndex = 0;
				let patientIndex = 0;
				this.headers = this.parseHeader(rows[0]);
				this.rawTableData = [];
				this.sliderTopMax = rows.length - 1;

				if(maxNbrEntries) {
					this.nbrTopEntries = this.sliderTopMax;
				}

				switch(fileType) {
					case this.clinicalType:
						variableIndex = 2;
						patientIndex = 4;
						break;
					case this.medicationType:
						variableIndex = 2;
						patientIndex = 3;
						break;
					case this.vitalSignType:
						variableIndex = 2;
						patientIndex = 4;
						break;
					default:
						throw "unknown file type " + fileType;
				}

				if(this.nbrTopEntries !== this.sliderTopMax) {
					rows = this.filterTop(rows, patientIndex, this.nbrTopEntries);
				}

				// start at second row
				for(let i=1; i<rows.length; i++) {
					let tokens = rows[i];

					if(!this.isEmpty(tokens)) {
						this.rawTableData.push(this.parseTableData(this.headers, tokens));
						[data, variables] = this.parseRow(tokens, data, variables, variableIndex, patientIndex);
					}
				}

				// reset this or table would be pre-filtered after loading a new dataset
				this.search = '';
				this.rawHeatmapData = data;
				this.cohorts = Object.keys(data);
				this.originalVariables = Object.keys(variables);
				[this.formattedHeatmapData, this.variables] = this.formatHeatmapData(data, this.originalVariables);
			},
			parseHeader: function(tokens) {
				let result = [];

				tokens.forEach((token) => {
					let tmp = {};
					tmp.text = token;
					tmp.value = token;
					result.push(tmp);
				});

				return result;
			},
			parseTableData: function(headers, tokens) {
				let result = {};

				for(let i=0; i<headers.length; i++) {
					let value = headers[i].value;
					result[value] = tokens[i];
				}

				return result;
			},
			guessType: function(fileName) {
				if(fileName.indexOf('clinical_tests') !== -1) {
					return this.clinicalType;
				} else if(fileName.indexOf('medication') !== -1) {
					return this.medicationType;
				} else if(fileName.indexOf('vital_signs') !== -1) {
					return this.vitalSignType;
				} else {
					throw 'unknown type for ' + fileName;
				}
			},
			/**
			 * parse content of row (array of tokens)
			 */
			parseRow: function(tokens, data, variables, variableIndex, patientIndex) {
				let cohort = tokens[0];
				let variable = tokens[variableIndex];
				let nbrPatients = +tokens[patientIndex];

				if(cohort && variable) {
					if(!data[cohort]) {
						data[cohort] = {};
					}

					data[cohort][variable] = nbrPatients;

					if(!variables[variable]) {
						variables[variable] = 0;
					}

					variables[variable]++;
				}

				return [data, variables];
			},
			/**
			 *
			 * @param data
			 * @param variables
			 * @param searchVariable
			 * @returns {[[], string[]]} heatmap data and heatmap variables, since both can be filtered
			 */
			formatHeatmapData: function(data, variables, searchVariable) {
				let result = [];
				let finalVariables = {};
				let cohorts = Object.keys(data);
				// reset this or heatmap min/max would be incorrect after loading a new dataset
				this.min = Number.MAX_SAFE_INTEGER; 
        this.max = -this.min;

				if(searchVariable) {
					searchVariable = searchVariable.trim().toLowerCase();
				}

				for(let i=0; i<cohorts.length; i++) {
					let cohort = cohorts[i];
					let realJ=0;

					for (let j=0; j<variables.length; j++) {
						let variable = variables[j];

						if(!searchVariable || !searchVariable.length || variable.toLowerCase().indexOf(searchVariable) !== -1) {
							finalVariables[variable] = true;
							let value = data[cohort][variable];

							if(value) {
								this.min = Math.min(this.min, value);
								this.max = Math.max(this.max, value);
							}

							result.push([i, realJ, value]);
							realJ++;
						}
					}
				}

				return [result, Object.keys(finalVariables)];
			},
			filterHeatmap: function(searchVariable) {
				[this.formattedHeatmapData, this.variables] = this.formatHeatmapData(this.rawHeatmapData, this.originalVariables, searchVariable);
			},
			parseBarplotData(csv) {
				let parsed = Papa.parse(csv, {
          skipEmptyLines: true
        });
				parsed = parsed.data;
				let header = [];
				let data = [];

				// skip header
				for(let i=1; i<parsed.length; i++) {
					let tokens = parsed[i];
					header.push(tokens[0]);
					data.push(+tokens[1]);
				}

				return [header, data];
			},
			/**
			 * reads file from filesystem, according to type
			 */
			loadData(type, maxNbrEntries) {
				this.loading = true;
				let path = 'data/';

				switch (type) {
					case this.clinicalType:
						path += this.clinicalFile;
						break;
					case this.medicationType:
						path += this.medicationFile;
						break;
					case this.vitalSignType:
						path += this.vitalSignFile;
						break;
					default:
						throw "unknown type " + type;
				}

				axios.get(path).then((response) => {
					let parsed = Papa.parse(response.data, {
						skipEmptyLines: true
					});
					this.parseData(parsed.data, type, maxNbrEntries);
					this.loading = false;
				});
			},
			datasetChange() {
				this.displayOptions = false;
				this.selectedType = null;
				this.topEntries = null;

				if(Number.isInteger(this.selectedDataset)) {
					let type = this.datasets[this.selectedDataset].text;

					if(type) {
						this.selectedType = type;
						this.loadData(type, true);
						this.displayOptions = true;
					}
				}
			},
			refreshHeatmap() {
				let element = document.getElementById(this.heatmapElementID);
				let chart = echarts.init(element);
				chart.setOption(this.heatmapOptions);
			},
			changeTopEntries() {
				this.loadData(this.selectedType, false);
			},
			/**
			 * returns only the first top entries (ranked by number of patients)
			 * @param rows
			 * @param patientIndex
			 * @param nbrTopEntries
			 * @returns {[]}
			 */
			filterTop(rows, patientIndex, nbrTopEntries) {
				// keep header
				let result = [rows[0]];
				let counts = {};

				// skip header
				// gather all counts and at which row they appear
				for(let i=1; i<rows.length; i++) {
					let count = +rows[i][patientIndex];

					if(Number.isInteger(count)) {
						if(!counts[count]) {
							counts[count] = [i];
						} else {
							counts[count].push(i);
						}
					}
				}

				// sort them
				let sortedCounts = Object.keys(counts).slice();
				// sort in place
				sortedCounts.sort(function(a, b) {
					return b - a;
				});

				// take the top entries
				for(let i=0; i<sortedCounts.length; i++) {
					if(result.length <= nbrTopEntries) {
						counts[sortedCounts[i]].forEach((index) => {
							result.push(rows[index]);
						})
					} else {
						break;
					}
				}

				return result;
			},
			isEmpty(row) {
				const found = row.find(element => element.length);

				if(found) {
					return false;
				}

				return true;
			}
		},

		mounted() {
			// not need to reload data later
			// this.axios.get('data/Rhapsody_patients_per_cohort.csv').then((response) => {
			axios.get('data/Rhapsody_patients_per_cohort.csv').then((response) => {
				[this.barplotXAxis, this.barplotData] = this.parseBarplotData(response.data);
				let element = document.getElementById("barchartElement");
				let chart = echarts.init(element);
				chart.setOption(this.barplotOptions);
			});
		}
	}
</script>

<style scoped>
	.heatmapChart {
		height: 1000px;
	}
	.barChart {
		height: 400px;
	}
</style>
