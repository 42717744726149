<template>
  <v-main>
    <v-container fluid class="pa-7">
      <v-row>
        <v-col>
          <h1 class="text-h6 mb-4">
            Experiments
          </h1>
        </v-col>
        <v-col>
          <v-text-field
            v-model="table.search"
            class="ma-0 pa-0"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableColumns"
        :items="experiments"
        item-key="id"
        :items-per-page="table.itemsPerPage"
        :search="table.search"
        :sort-by="table.sortBy"
      >
        <template #[`item.code`]="{ item }">
          <router-link :to="'/experiment/' + item.id">{{ item.code }}</router-link>
        </template>
        <template #[`item.study.name`]="{ item }">
          <router-link :to="'/study/' + item.study.id">{{ item.study.name }}</router-link>
        </template>
        <template #[`item.project.name`]="{ item }">
          <router-link :to="'/project/' + item.project.id">{{ item.project.name }}</router-link>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-icon v-if="item.study.is_public" @click="downloadData(item.id)">mdi-download</v-icon>
          <v-icon v-else @click.stop="sendDownloadRequest(item.id, $event)">mdi-send</v-icon>
        </template>
        <template #[`footer.prepend`]>
          <vue-json-to-csv :json-data="experiments" :labels="file.fields" :csv-title="file.name">
            <v-btn outlined color="indigo">
              <v-icon>mdi-download</v-icon>
              Export
            </v-btn>
          </vue-json-to-csv>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog" max-width="400">
        <v-card>
          <v-card-title class="text-h5">
            Request Sent
          </v-card-title>

          <v-card-text>
            Your download request was sent to the data custodian.<br />
            You'll be notified by email, once it is approved.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import http from "@/router/http";
import util from "../helpers/utilities.js";
import VueJsonToCsv from "vue-json-to-csv";
import { mapGetters } from "vuex";

export default {
  name: "Experiments",

  components: {
    VueJsonToCsv,
  },

  data() {
    return {
      file: {
        name: "experiments",
        fields: {
          code: { title: "Code" },
          name: { title: "Name" },
          study_name: { title: "Study" },
          project_name: { title: "Project" },
        },
      },
      table: {
        columns: [
          {
            text: "Code",
            value: "code",
          },
          {
            text: "Name",
            value: "name",
          },
          {
            text: "Study",
            value: "study.name",
          },
          {
            text: "Project",
            value: "project.name",
          },
        ],
        search: "",
        itemsPerPage: 15,
        sortBy: "code",
      },
      dialog: false,
    };
  },

  computed: {
    tableColumns() {
      const columns = this.table.columns;
      if (this.$keycloak.authenticated) {
        columns.push({
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
        });
      }
      return columns;
    },
    ...mapGetters({
      getExperiments: "experiment/all",
      getStudy: "study/getById",
      getProject: "project/getById",
    }),
    experiments() {
      const experiments = [];
      for (let experiment of this.getExperiments) {
        const study = this.getStudy(experiment.study_id);
        const project = this.getProject(study.project_id);
        experiments.push({
          ...experiment,
          study,
          project,
          study_name: study.name,
          project_name: project.name,
        });
      }
      return experiments;
    },
  },

  methods: {
    downloadData: function(experimentId) {
      return util.loadFile("experiments/" + experimentId + "/data");
    },

    sendDownloadRequest: function(experimentId, event) {
      http.get("experiments/" + experimentId + "/data/request").then(() => {
        event.target.classList.replace("mdi-send", "mdi-account-clock");
        this.dialog = true;
      });
    },
  },
};
</script>

<style scoped></style>
