<template>
  <v-main>
    <v-container fluid>
      <!-- title -->
      <v-row>
        <v-col>
          <v-card flat>
            <v-card-title>
              Project
            </v-card-title>
            <v-card-text>
              {{ project.name }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- description -->
      <v-row v-if="project.description">
        <v-col>
          <v-card flat>
            <v-card-title>
              Description
            </v-card-title>
            <v-card-text>
              {{ project.description }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- studies -->
      <v-row>
        <v-col>
          <v-card flat>
            <v-card-title>
              <v-row>
                <v-col>
                  {{ studyCount }} Studies
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="table.search"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="table.columns"
                :items="studies"
                item-key="id"
                :item-class="getRowClass"
                :items-per-page="table.itemsPerPage"
                :search="table.search"
                :sort-by="table.sortBy"
              >
                <template #[`item.code`]="{ item }">
                  <router-link :to="'/study/' + item.id">{{ item.code }}</router-link>
                </template>
                <template #[`item.description`]="{ item }">
                  <span v-html="item.description"></span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Project",

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      project: null,
      studies: [],
      table: {
        columns: [
          {
            text: "Code",
            value: "code",
            width: "7%",
          },
          {
            text: "Name",
            value: "name",
          },
          {
            text: "Description",
            value: "description",
            width: "60%",
          },
          {
            text: "Species",
            value: "species_name",
            width: "7%",
          },
          {
            text: "Experiments",
            value: "experiment_count",
            width: "10%",
            align: "center",
          },
          {
            text: "Public",
            value: "is_public",
            width: "7%",
            align: "center",
          },
        ],
        search: "",
        itemsPerPage: 10,
        sortBy: "code",
      },
    };
  },

  computed: {
    ...mapGetters({
      getConceptName: "concept/getName",
      getExperiments: "experiment/all",
      getProject: "project/get",
      getStudies: "study/filterByProject",
    }),

    studyCount: function() {
      return this.studies.length;
    },

    experimentCount() {
      const counts = new Map();
      for (let experiment of this.getExperiments) {
        let study_id = experiment.study_id;
        if (counts.has(study_id)) {
          counts.set(
            experiment.study_id,
            counts.get(study_id) + 1
          );
        } else {
          counts.set(study_id, 1);
        }
      }
      return counts;
    },
  },

  methods: {
    getExperimentCount(study_id) {
      return this.experimentCount.get(study_id) || 0;
    },
    getRowClass(item) {
      return !item.experiment_count ? "grey--text" : "";
    },
  },

  created() {
    this.project = this.getProject(this.id);
    if (this.project === undefined) {
      this.$router.push({ name: "Home" });
    }
    const studies = [];
    for (let study of this.getStudies(this.id)) {
      studies.push({
        ...study,
        is_public: study.is_public ? "yes" : "no",
        species_name: this.getConceptName(study.species_concept_id),
        experiment_count: this.getExperimentCount(study.id),
      });
    }
     this.studies = studies;
  },
};
</script>

<style scoped></style>
