<!-- results page -->

<template>
  <v-app>
    <v-app-bar app color="white">
      <NavBar />
    </v-app-bar>

    <DownloadJsonCsv ref="downloadJsonCsv" file-type="csv" :fields="csvFields" />

    <v-main>
      <v-container fluid>
        <!-- search -->
        <v-row>
          <v-col cols="2">
            <Analytes />
          </v-col>
          <v-col cols="10">
            <SearchBar />
            <v-container>
              <v-row v-show="comparisonCount">
                <v-col class="d-flex justify-center title">
                  Found {{ comparisonCount }} comparisons in {{ experimentCount }} experiments ordered by score
                </v-col>
              </v-row>
              <v-row v-show="getNotFound.length">
                <v-col class="d-flex justify-center title">
                  The following analytes were not found: {{ getNotFound.join(", ") }}
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <!-- page content -->
        <v-row>
          <!-- filters -->
          <v-col cols="2">
            <h6 class="section-title text-h6" v-show="displayFilters">
              Filters
              <v-icon class="action-icon" v-on:click.prevent="discardAllFilters()" v-if="hasActiveFilters" small
                >mdi-close</v-icon
              >
            </h6>
            <v-container id="filters" v-show="displayFilters" fluid>
              <selection-list
                title="Projects"
                :options="getProjects"
                ref="projectFilter"
                @change="applyFilter('projects', $event)"
              ></selection-list>
              <selection-list
                title="Analyte Types"
                :options="analyteTypes"
                ref="analyteTypeFilter"
                @change="applyFilter('analyteTypes', $event)"
              ></selection-list>
              <selection-list
                title="Species"
                :options="species"
                ref="speciesFilter"
                @change="applyFilter('species', $event)"
              ></selection-list>
              <selection-list
                title="Tissues"
                :options="specimenTypes"
                ref="specimenTypeFilter"
                @change="applyFilter('specimenTypes', $event)"
              ></selection-list>
            </v-container>
          </v-col>
          <!-- table and heatmap(s) -->
          <v-col>
            <v-tabs v-model="currentTab" @change="changeTab">
              <v-tab key="table">Results table {{ getResultsTableTabLabel() }}</v-tab>
              <v-tab v-for="(heatmap, type) in heatmaps" :key="type">
                Heatmap ({{ getHeatmapTabLabel(heatmap.analytes, type) }})
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="currentTab">
              <v-tab-item key="table">
                <v-card>
                  <v-card-title v-show="hasComparisons">
                    <v-col>
                      <v-btn outlined color="indigo" @click="exportResults()" :disabled="!isSelectionActive">
                        <v-icon>mdi-download</v-icon>
                        Export
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details>
                      </v-text-field>
                    </v-col>
                  </v-card-title>
                  <v-card-subtitle v-if="allItemsSelected">
                    <span v-if="!allPagesItemsSelected"
                      >All {{ selectedEntries.length }} results on this page are selected.
                      <a @click="selectAllEntries">Select all {{ comparisonResults.length }} results.</a>
                    </span>
                    <span v-else
                      >All {{ comparisonResults.length }} results are selected.
                      <a @click="unselectAllEntries">Clear selection.</a>
                    </span>
                  </v-card-subtitle>
                  <v-data-table
                    v-model="selectedEntries"
                    :headers="resultTableColumns"
                    :items="comparisonResults"
                    item-key="comparison_id"
                    :items-per-page="itemsPerPage"
                    :search="search"
                    :sort-by="sortBy"
                    show-select
                    @toggle-select-all="handleSelectAll"
                    @item-selected="selectEntry"
                  >
                    <template #[`item.analyte_symbol`]="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{ item.analyte_symbol || getAnalyteCode(item.analyte_id) }}</span>
                        </template>
                        <span>{{ getAnalyteCode(item.analyte_id) }} <br/> {{ getAnalyteName(item.analyte_id) }}</span>
                      </v-tooltip>
                    </template>
                    <template #[`item.comparison_name`]="{ item }">
                      <router-link :to="'/experiment/' + item.experiment_id">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="grey lighten-1" class="float-right">
                              mdi-test-tube
                            </v-icon>
                          </template>
                          <span>{{ item.experiment_name }}</span>
                        </v-tooltip>
                      </router-link>
                      <router-link :to="'/comparison/' + item.comparison_id">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">{{ item.comparison_name }}</span>
                          </template>
                          <span>{{ 'Count per Cohort: ' + comparisonBiospecimenSetCount(item.comparison_id) }}</span>
                        </v-tooltip>
                      </router-link>
                    </template>
                    <template #[`item.study_name`]="{ item }">
                      <router-link :to="'/study/' + item.study_id">{{ item.study_name }}</router-link>
                    </template>
                    <template #[`item.project_name`]="{ item }">
                      {{ item.project_name }}
                    </template>
                    <template #[`item.p_value`]="{ item }">
                      {{ formatSciNum(item.p_value) }}
                    </template>
                    <template #[`item.adjusted_p_value`]="{ item }">
                      {{ formatSciNum(item.adjusted_p_value) }}
                    </template>
                    <template #[`item.effect_size`]="{ item }">
                      {{ formatNumber(item.effect_size, 2) }}
                      <v-chip x-small>
                        FC
                      </v-chip>
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <v-icon
                        @click.stop="
                          dialogTitle = item.comparison_name;
                          showObservationResults(item.comparison_id);
                        "
                        >mdi-chart-timeline</v-icon
                      >
                    </template>
                  </v-data-table>
                </v-card>
              </v-tab-item>
              <v-tab-item v-for="(heatmap, type) in heatmaps" :key="type">
                <Heatmaps :analytes="Object.values(heatmap.analytes)" :analyte-type-id="+type" />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <template>
        <v-dialog v-model="dialog" scrollable width="650">
          <v-card id="details-dialog">
            <v-card-title class="subtitle-1 grey lighten-4">
              {{ dialogTitle }}
            </v-card-title>
            <v-card-text>
              <box-plot
                :axis-data="boxPlotLabels"
                :data-values="boxPlotValues"
                :key="boxPlotLabels.join('_')"
                v-if="observationResults.size"
              />
              <v-alert outlined type="warning" prominent v-else>
                Observation results are not yet available for this comparison.
              </v-alert>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-main>
  </v-app>
</template>

<script>
import _ from "lodash";
import util from "../helpers/utilities.js";
import SearchBar from "../components/SearchBar";
import NavBar from "../components/NavBar";
import BoxPlot from "../components/BoxPlot";
import Heatmaps from "../components/Heatmaps";
import DownloadJsonCsv from "../components/DownloadJsonCsv";
import SelectionList from "../components/SelectionList";
import Analytes from "../components/Analytes";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Results",

  components: {
    SearchBar,
    NavBar,
    BoxPlot,
    DownloadJsonCsv,
    SelectionList,
    Analytes,
    Heatmaps,
  },

  data() {
    return {
      defaultColumns: [
        {
          text: "Analyte",
          value: "analyte_symbol",
        },
        {
          text: "Comparison",
          value: "comparison_name",
        },
        {
          text: "Study",
          value: "study_name",
        },
        {
          text: "Project",
          value: "project_name",
        },
        {
          text: "P-value",
          value: "p_value",
          align: "right",
        },
        {
          text: "Adj. P-value",
          value: "adjusted_p_value",
          align: "right",
        },
        {
          text: "Effect Size",
          value: "effect_size",
          align: "right",
        },
      ],
      dialog: false,
      dialogTitle: "",
      search: "",
      selectedProjects: [],
      selectedSpecies: [],
      selectedSpecimenTypes: [],
      selectedAnalyteTypes: [],
      selectedEntries: [],
      allItemsSelected: false,
      allPagesItemsSelected: false,
      itemsPerPage: 15,
      sortBy: "p_value",
      currentTab: 0,
      displayFilters: false,
    };
  },

  computed: {
    ...mapGetters({
      getConceptName: "concept/getName",
      getConceptsByDomain: "concept/filterByDomain",
      getProjects: "project/all",
      analytes: "analyte/all",
      getAnalyteCode: "analyte/getCode",
      getAnalyteName: "analyte/getName",
      getAnalyte: "analyte/get",
      activeAnalyteId: "analyte/getActiveId",
      analyteIds: "analyte/ids",
      getBiospecimenSetName: "comparison/getBiospecimenSetName",
      hasActiveFilters: "comparison/hasActiveSearchFilters",
      getComparisonProperty: "comparison/getPropertyValue",
      experimentCount: "comparison/getExperimentCount",
      comparisonCount: "comparison/getComparisonCount",
      comparisonResults: "comparison/getActiveResults",
      observationResults: "observation/getGroupedResults",
      getNotFound: "analyte/getNotFound",
    }),

    resultTableColumns() {
      const columns = this.defaultColumns;
      if (this.$keycloak.authenticated) {
        columns.push({
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
        });
      }
      return columns;
    },

    species() {
      return this.getConceptsByDomain("species");
    },

    specimenTypes() {
      return this.getConceptsByDomain("specimens");
    },

    analyteTypes() {
      return this.getConceptsByDomain("analytes");
    },

    boxPlotLabels() {
      const codes = Array.from(this.observationResults.keys());
      return codes.map((code) => {
        let name = this.getBiospecimenSetName(code);
        if (name) {
          return name.replace(/ - /g, "\n");
        }
        return code;
      });
    },

    boxPlotValues() {
      return Array.from(this.observationResults.values());
    },

    isSelectionActive() {
      return this.selectedEntries.length > 0;
    },

    hasComparisons() {
      return this.comparisonCount > 0;
    },

    csvFields() {
      return _.map(this.resultsHeader, "value").slice(0, -1);
    },

    /**
     * gather and format data for heatmap, one per type of analyte
     */
    heatmaps() {
      let result = {};

      if (this.analytes.length) {
        this.analytes.forEach((analyte) => {
          const analyteTypeId = analyte.analyte_concept_id;
          if (!result[analyteTypeId]) {
            result[analyteTypeId] = {};
            result[analyteTypeId].analytes = {};
          }
          result[analyteTypeId].analytes[analyte.id] = analyte;
        });
      }

      return result;
    },
  },

  watch: {
   heatmaps: function() {
      // force focus on last heatmap
      // console.log(this.heatmaps);
      let selectedTab = Object.keys(this.heatmaps).length;
      // not sure about the rest, find the tab with the highest number of analytes ?
      /*let maxNbrAnalytes = 0;
      let heatmapIndex = 0;

      for (const key in this.heatmaps) {
        console.log(this.heatmaps[key].analytes);
        const nbrAnalytes = Object.keys(this.heatmaps[key].analytes).length;
        console.log(nbrAnalytes);
        
        if (nbrAnalytes >= maxNbrAnalytes) {
          maxNbrAnalytes = nbrAnalytes;
          selectedTab = heatmapIndex + 1;
        }

        heatmapIndex++;
      }
      console.log(selectedTab);*/

      this.currentTab = selectedTab;
    },

    analyteIds(ids) {
      if (ids.length === 0) {
        this.discardAllFilters();
      }
    },
  },

  methods: {
    ...mapActions({
      loadObservationResults: "observation/loadResults",
      setFilter: "comparison/setSearchFilter",
      resetFilters: "comparison/resetSearchFilters",
    }),

    formatNumber(number) {
      return util.formatNumber(number);
    },

    formatSciNum(number) {
      return util.formatSciNum(number);
    },

    comparisonBiospecimenSetCount(comparisonId) {
      const property = this.getComparisonProperty(comparisonId, "biospecimen_sets");

      if(property) {
        return Object.values(property).join(" vs ");
      }

      return "NA";
    },

    showObservationResults(comparisonId) {
      this.loadObservationResults({
        analyte_id: this.activeAnalyteId,
        comparison_id: comparisonId,
      })
        .then(() => {
          this.dialog = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    exportResults() {
      // add columns experiment and study
      let selEntries = _.map(this.selectedEntries, (entry) => {
        return {
          ...entry,
          experiment: entry.experiment_name,
          study: entry.study_name,
        };
      });

      this.$refs.downloadJsonCsv.handleDownload(selEntries);
    },

    handleSelectAll() {
      // we have to double check that nothing got mixed up
      this.allItemsSelected = this.selectedEntries.length < this.itemsPerPage ? true : false;
    },

    selectAllEntries() {
      this.selectedEntries = this.comparisonResults;
      this.allPagesItemsSelected = true;
    },

    unselectAllEntries() {
      this.selectedEntries = [];
      this.allItemsSelected = false;
      this.allPagesItemsSelected = false;
    },

    selectEntry() {
      this.allItemsSelected = false;
      this.allPagesItemsSelected = false;
    },

    // @TODO this has to be called when results are reloaded.
    onResultsReloaded() {
      this.allItemsSelected = false;
      this.allPagesItemsSelected = false;
      this.selectedEntries = [];
    },

    applyFilter(key, selection) {
      let value = selection.map((a) => a.id);
      this.setFilter({ key, value });
    },

    discardAllFilters() {
      this.$refs.projectFilter.clear();
      this.$refs.analyteTypeFilter.clear();
      this.$refs.speciesFilter.clear();
      this.$refs.specimenTypeFilter.clear();
      this.resetFilters();
    },

    /**
     * dynamically adapt results table label according to which analyte is currently selected
     */
    getResultsTableTabLabel() {
      const analyte = this.getAnalyte(this.activeAnalyteId);
      const result = analyte ? "(" + (analyte.symbol || analyte.code) + ")" : "";

      return result;
    },

    /**
     * dynamically adapt heatmap label to what is actually used for the heatmap
     */
    getHeatmapTabLabel(analytes, type) {
      const number = Object.keys(analytes).length;
      const plural = number > 1 ? "s" : "";
      const result = number + " " + this.getConceptName(+type) + plural;

      return result;
    },

    changeTab: function() {
      // console.log("change tab " + tab);
    },
  },
};
</script>

<style lang="scss" scoped>
#details-dialog {
  .v-card__text {
    padding: 20px;
  }
  .v-alert {
    margin: 0;
  }
}

#filters {
  padding: 0;
  .v-input--checkbox,
  .v-input__slot {
    margin: 0;
  }
}

.v-data-table {
  .v-chip {
    margin-top: -3px;
    padding: 0 6px;
  }
  .v-row-group__header {
    a {
      text-decoration: none;
    }
  }
}

.section-title {
  padding: 5px 0;
  .action-icon {
    float: right;
    margin: 10px 14px 0 0;
  }
}
</style>
