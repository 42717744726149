import http from '@/router/http';
import mixins from '../mixins';

const codeSeparator = '-';
const comparisonPattern = /^[A-Z0-9_]{2,}x[A-Z0-9_]{2,}$/;
const datetimeCodes = {
  Y: 'YEAR',
  M: 'MONTH',
  W: 'WEEK',
  D: 'DAY',
  H: 'HOUR',
  I: 'MINUTE',
  S: 'SECOND',
};

function getTimepointName(code, getConceptName) {
  const timepointPattern = /^([A-Z]+)([0-9]+)$/;
  const timepoint = timepointPattern.exec(code);
  if (timepoint) {
    let [, unit, value] = timepoint;
    unit = getConceptName(datetimeCodes[unit]);
    if (unit) {
      return unit + ' ' + value;
    }
  }
  return null;
}

function getNameFromCode(code, getConceptName) {
  let [templateId, ...codeSegments] = code.split(codeSeparator);
  let isComparison = false;
  if (templateId.startsWith('X')) {
    isComparison = true;
    templateId = templateId.substring(1);
  }
  const params = [codeSegments, isComparison, getConceptName];
  switch (templateId) {
    case 'C1':
      return getC1Name(...params);
    case 'H1':
      return getH1Name(...params);
    case 'H2':
      return getH2Name(...params);
    case 'M1':
      return getM1Name(...params);
    case 'M2':
      return getM2Name(...params);
    case 'M3':
      return getM3Name(...params);
    case 'R1':
      return getR1Name(...params);
  }
  return null;
}

function getC1Name(codeSegments, isComparison, getConceptName) {
  if (codeSegments.length !== 5) {
    return null;
  }
  let [, , cellLineCode, transfectionCode, milieuCode] = codeSegments;
  let name = getConceptName(cellLineCode) + ' - ' + getConceptName(transfectionCode) + ' - ';
  if (isComparison) {
    if (comparisonPattern.test(milieuCode)) {
      milieuCode = milieuCode.split('x');
      name += getConceptName(milieuCode[0]) + ' vs ' + getConceptName(milieuCode[1]);
    } else {
      return null;
    }
  } else {
    name += getConceptName(milieuCode);
  }
  return name;
}

function getH1Name(codeSegments, isComparison, getConceptName) {
  if (codeSegments.length !== 3) {
    return null;
  }
  let conditionCode = codeSegments[2];
  let name;
  if (isComparison) {
    if (comparisonPattern.test(conditionCode)) {
      conditionCode = conditionCode.split('x');
      name = getConceptName(conditionCode[0]) + ' vs ' + getConceptName(conditionCode[1]);
    } else {
      return null;
    }
  } else {
    name = getConceptName(conditionCode);
  }
  return name;
}

function getH2Name(codeSegments, isComparison, getConceptName) {
  if (codeSegments.length !== 1) {
    return null;
  }
  let treatmentCode = codeSegments[0];
  let name;
  if (isComparison) {
    if (comparisonPattern.test(treatmentCode)) {
      treatmentCode = treatmentCode.split('x');
      name = getConceptName(treatmentCode[0]) + ' vs ' + getConceptName(treatmentCode[1]);
    } else {
      return null;
    }
  } else {
    name = getConceptName(treatmentCode);
  }
  return name;
}

function getM1Name(codeSegments, isComparison, getConceptName) {
  if (codeSegments.length !== 5) {
    return null;
  }
  let [, , strainCode, dietCode, timepointCode] = codeSegments;
  let name = getConceptName(strainCode) + ' - ';
  name += (getTimepointName(timepointCode, getConceptName) || timepointCode) + ' - ';
  if (isComparison) {
    if (comparisonPattern.test(dietCode)) {
      dietCode = dietCode.split('x');
      name += getConceptName(dietCode[0]) + ' vs ' + getConceptName(dietCode[1]);
    } else {
      return null;
    }
  } else {
    name += getConceptName(dietCode);
  }
  return name;
}

function getM2Name(codeSegments, isComparison, getConceptName) {
  if (codeSegments.length !== 5) {
    return null;
  }
  let [, , strainCode, genotypeCode, treatmentCode] = codeSegments;
  let name = getConceptName(strainCode) + ' - ' + getConceptName(genotypeCode) + ' - ';
  if (isComparison) {
    if (comparisonPattern.test(treatmentCode)) {
      treatmentCode = treatmentCode.split('x');
      name += getConceptName(treatmentCode[0]) + ' vs ' + getConceptName(treatmentCode[1]);
    } else {
      return null;
    }
  } else {
    name += getConceptName(treatmentCode);
  }
  return name;
}

function getM3Name(codeSegments, isComparison, getConceptName) {
  if (codeSegments.length !== 5) {
    return null;
  }
  let [, strainCode, timepointCode, cellLineCode, genotypeCode] = codeSegments;
  let name = getConceptName(strainCode) + ' - ';
  name += (getTimepointName(timepointCode, getConceptName) || timepointCode) + ' - ';
  name += getConceptName(cellLineCode) + ' - ';
  if (isComparison) {
    if (comparisonPattern.test(genotypeCode)) {
      genotypeCode = genotypeCode.split('x');
      name += getConceptName(genotypeCode[0]) + ' vs ' + getConceptName(genotypeCode[1]);
    } else {
      return null;
    }
  } else {
    name += getConceptName(genotypeCode);
  }
  return name;
}

function getR1Name(codeSegments, isComparison, getConceptName) {
  if (codeSegments.length !== 4) {
    return null;
  }
  let [, , genotypeCode, treatmentCode] = codeSegments;
  let name = getConceptName(genotypeCode) + ' - ';
  if (isComparison) {
    if (comparisonPattern.test(treatmentCode)) {
      treatmentCode = treatmentCode.split('x');
      name += getConceptName(treatmentCode[0]) + ' vs ' + getConceptName(treatmentCode[1]);
    } else {
      return null;
    }
  } else {
    name += getConceptName(treatmentCode);
  }
  return name;
}

const state = () => ({
  ...mixins.getDefaultState(),
  search: {
    criteria: {
      projects: [],
      analyteTypes: [],
      species: [],
      specimenTypes: [],
    },
  },
  results: new Map(),
});

const getters = {
  ...mixins.getDefaultGetters(),

  getExpanded: (state, getters, rootState, rootGetters) => (key) => {
    const comparison = getters.get(key);
    if (!comparison) {
      return undefined;
    }
    const experiment = rootGetters['experiment/get'](comparison.experiment_id),
      study = rootGetters['study/get'](experiment.study_id),
      project = rootGetters['project/get'](study.project_id);
    return {
      ...comparison,
      experiment,
      study,
      project,
    };
  },

  getName: (state, getters) => (key) => {
    return getters.getPropertyValue(key, 'name');
  },

  getBiospecimenSetName: (state, getters, rootState, rootGetters) => (code) => {
    return getNameFromCode(code, rootGetters['concept/getDisplayName']);
  },

  filterByExperiment: (state, getters, rootState, rootGetters) => (key) => {
    const id = Number.isInteger(key) ? key : rootGetters['experiment/getId'](key);
    return getters.filterBy('experiment_id', id);
  },

  getExpandedProperties: (state, getters) => (key) => {
    const comparison = getters.getExpanded(key);
    return {
      comparison_name: comparison.name,
      experiment_id: comparison.experiment.id,
      experiment_name: comparison.experiment.name,
      study_id: comparison.study.id,
      study_name: comparison.study.name,
      project_id: comparison.project.id,
      project_name: comparison.project.name,
    };
  },

  searchParams: (state) => {
    const criteria = state.search.criteria;
    const params = {};
    if (criteria.projects.length) {
      params.project_ids = criteria.projects.join(',');
    }
    if (criteria.analyteTypes.length) {
      params.analyte_concept_ids = criteria.analyteTypes.join(',');
    }
    if (criteria.species.length) {
      params.species_concept_ids = criteria.species.join(',');
    }
    if (criteria.specimenTypes.length) {
      params.specimen_concept_ids = criteria.specimenTypes.join(',');
    }
    return params;
  },

  hasActiveSearchFilters: (state) => {
    for (const value of Object.values(state.search.criteria)) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          return true;
        }
      } else if (value !== null) {
        return true;
      }
    }
    return false;
  },

  categoryIndex: (state, getters) => {
    const index = new Map();
    const aliases = {
      timepoints: 'timepoint',
      treatments: 'treatment',
      species: 'species',
      sexes: 'sex',
      races: 'race',
      countries: 'country',
      strains: 'strain',
      cells: 'cell',
    };
    state.entities.forEach((entity, id) => {
      const comparison = getters.getExpanded(id);
      const categories = {
        omics: comparison.experiment.omics_concept_id,
        technology: comparison.experiment.technology_concept_id,
        tissue: comparison.experiment.specimen_concept_id,
        analyte: comparison.experiment.analyte_concept_id,
        species: comparison.study.species_concept_id,
      };
      const properties = comparison.details || {};
      for (const [key, values] of Object.entries(properties)) {
        const alias = aliases[key];
        if (!alias) {
          continue;
        }
        if (Array.isArray(values) && values.length === 1) {
          categories[alias] = values[0];
        }
      }
      index.set(comparison.id, categories);
    });
    return index;
  },

  resultIndex: (state) => {
    const index = new Map();
    state.results.forEach((results, analyteId) => {
      results.forEach((result) => {
        const comparisonId = result.comparison_id;
        const comparisonIds = index.get(analyteId);
        if (comparisonIds) {
          comparisonIds.push(comparisonId);
        } else {
          index.set(analyteId, [comparisonId]);
        }
      });
    });
    return index;
  },

  getResultFilters: (state, getters, rootState, rootGetters) => (analyteTypeId) => {
    const filters = {};
    const categories = {};
    const analytes = rootGetters['analyte/filterByType'](analyteTypeId);
    analytes.forEach((analyte) => {
      const comparisonIds = getters.resultIndex.get(analyte.id) || [];
      comparisonIds.forEach((comparisonId) => {
        const properties = getters.categoryIndex.get(comparisonId) || {};
        for (const [name, value] of Object.entries(properties)) {
          if (!categories[name]) {
            categories[name] = {};
          }
          if (!categories[name][value]) {
            categories[name][value] = new Set();
          }
          categories[name][value].add(comparisonId);
        }
      });
    });
    for (const [name, values] of Object.entries(categories)) {
      if (!filters[name]) {
        filters[name] = {};
      }
      for (let [value, comparisonIds] of Object.entries(values)) {
        const conceptId = Number.parseInt(value, 10);
        if (!isNaN(conceptId)) {
          value = rootGetters['concept/getName'](conceptId);
        }
        if (value) {
          filters[name][value] = Array.from(comparisonIds);
        }
      }
    }
    return filters;
  },

  getAllResults: (state) => {
    return state.results;
  },

  getResults: (state) => (analyteId) => {
    return state.results.get(analyteId);
  },

  getActiveResults: (state, getters, rootState, rootGetters) => {
    const analyteId = rootGetters['analyte/getActiveId'];
    return getters.getResults(analyteId);
  },

  getComparisonCount: (state, getters) => {
    return getters.getActiveResults ? getters.getActiveResults.length : 0;
  },

  getExperimentCount: (state, getters) => {
    const results = getters.getActiveResults || [];
    const experiments = new Set(results.map((result) => result.experiment_id));
    return experiments.size;
  },
};

const mutations = {
  ...mixins.getDefaultMutations(),

  setSearchCriteria(state, criteria) {
    state.search.criteria = criteria;
  },

  insertResults(state, data) {
    const results = new Map(state.results);
    Object.keys(data).forEach((analyteId) => {
      results.set(Number.parseInt(analyteId, 10), data[analyteId]);
    });
    state.results = results;
  },

  deleteResults(state, analyteId) {
    const results = new Map(state.results);
    results.delete(analyteId);
    state.results = results;
  },

  deleteAllResults(state) {
    state.results = new Map();
  },
};

const actions = {
  updateNames({ commit, getters, rootGetters }) {
    const entities = getters.all;
    const getConceptName = rootGetters['concept/getDisplayName'];
    const compositeCodePattern = /^X[A-Z][0-9]-/;
    entities.forEach((entity) => {
      if (!compositeCodePattern.test(entity.code)) {
        return;
      }
      let name = getNameFromCode(entity.code, getConceptName);
      if (name) {
        entity.name = name;
      }
    });
    commit('insert', entities);
  },

  setSearchFilter({ commit, state }, { key, value }) {
    const criteria = { ...state.search.criteria };
    if (Object.hasOwn(criteria, key)) {
      criteria[key] = value;
      commit('setSearchCriteria', criteria);
    }
  },

  resetSearchFilters({ commit, state }) {
    const criteria = {};
    for (const [key, value] of Object.entries(state.search.criteria)) {
      criteria[key] = Array.isArray(value) ? [] : null;
    }
    commit('setSearchCriteria', criteria);
  },

  loadResults({ commit, getters }, params) {
    return http
      .get('comparisons/all/results', {
        params: {
          analyte_ids: params.analyte_id || '',
          analyte_keys: params.analyte_keys || '',
          project_ids: params.project_ids || '',
          analyte_concept_ids: params.analyte_concept_ids || '',
          species_concept_ids: params.species_concept_ids || '',
          specimen_concept_ids: params.specimen_concept_ids || '',
          expansions: 'analyte_id',
          analyte_fields: '*',
          include_missing: 'analyte_keys',
          options: 'disable_pagination',
        },
      })
      .then((response) => response.data)
      .then((payload) => {
        if (payload.data.length === 0) {
          return;
        }
        const results = {};
        const analytes = new Map();
        const fields = payload.meta.fields;
        let analyteId = null;
        payload.includes.analytes.forEach((analyte) => {
          analytes.set(analyte.id, analyte);
        });
        payload.data.forEach((record) => {
          const values = {};
          record.forEach((value, index) => {
            values[fields[index]] = value;
          });
          analyteId = values.analyte_id;
          values.analyte_symbol = analytes.get(analyteId).symbol;
          if (!Object.prototype.hasOwnProperty.call(results, analyteId)) {
            results[analyteId] = [];
          }
          results[analyteId].push({
            ...values,
            ...getters.getExpandedProperties(values.comparison_id),
          });
        });

        let notFound = [];
        if (payload.meta && payload.meta.not_found && payload.meta.not_found.analyte_keys) {
          notFound = payload.meta.not_found.analyte_keys;
        }

        commit('insertResults', results);
        commit('analyte/insert', [...analytes.values()], { root: true });
        commit('analyte/setActive', analyteId, { root: true });
        commit('analyte/setNotFound', notFound, { root: true });
      });
  },

  removeResults({ commit }, analyteId) {
    commit('deleteResults', analyteId);
  },

  removeAllResults({ commit }) {
    commit('deleteAllResults');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
