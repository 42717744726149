<template>
  <sup>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" small>mdi-help-circle</v-icon>
      </template>
      <span>tooltip</span>
    </v-tooltip>
  </sup>
</template>

<script>
export default {
  name: "Tooltip",
};
</script>

<style scoped></style>
