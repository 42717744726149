<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card flat>
            <v-card-title>
              Experiment
            </v-card-title>
            <v-card-text>
              {{ experiment.code }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- study -->
      <v-row>
        <v-col>
          <v-card flat>
            <v-card-title>
              Study
              <v-spacer></v-spacer>
              <v-icon v-if="$keycloak.authenticated && experiment.study.is_public" @click="downloadData(experiment.id)"
                >mdi-download</v-icon
              >
            </v-card-title>
            <v-card-text>
              <p>
                <router-link :to="'/study/' + experiment.study.id">
                  {{ experiment.study.name }}
                </router-link>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- omics -->
      <v-row>
        <v-col>
          <v-card flat>
            <v-card-title>
              Omics
            </v-card-title>
            <v-card-text>
              <p>{{ getConceptName(experiment.omics_concept_id) }}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- tissues -->
      <v-row>
        <v-col>
          <v-card flat>
            <v-card-title>
              Technology
            </v-card-title>
            <v-card-text>
              <p>{{ getConceptName(experiment.technology_concept_id) }}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <!-- analyte -->
        <v-col>
          <v-card flat>
            <v-card-title>
              Tissue
            </v-card-title>
            <v-card-text>
              <p>{{ getConceptName(experiment.specimen_concept_id) }}</p>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- hide/show -->
        <v-col class="d-flex justify-end align-center">
          <v-btn dark @click="showComparisons = !showComparisons">
            {{ buttonLabel }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- comparisons -->
      <v-row v-if="showComparisons">
        <v-col>
          <v-card flat>
            <v-card-title> {{ comparisonCount || "" }} Comparisons </v-card-title>
            <v-card-text>
              <v-simple-table v-if="comparisonCount">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Comparison</th>
                      <th class="text-left">Statistical Test</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(comparison, index) in comparisons" :key="index">
                      <td>
                        <router-link :to="'/comparison/' + comparison.id">{{ comparison.name }}</router-link>
                      </td>
                      <td>{{ getConceptName(comparison.test_concept_id) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-alert v-else dense outlined type="warning"
                >Not yet available. For more information, please contact
                <a href="mailto:epdc@sib.swiss">epdc@sib.swiss</a>.</v-alert
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import util from "../helpers/utilities.js";
import { mapGetters } from "vuex";
import { BASE_API_URL } from "@/app.config";

export default {
  name: "Experiment",

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      BASE_API_URL,
      experiment: null,
      comparisons: [],
      showComparisons: true,
    };
  },

  computed: {
    ...mapGetters({
      getConceptName: "concept/getName",
      getExperiment: "experiment/getExpanded",
      getComparisons: "comparison/filterByExperiment",
    }),

    comparisonCount: function() {
      return this.comparisons.length;
    },

    buttonLabel: function() {
      if (this.showComparisons) {
        return "Hide comparisons";
      }

      return "Show comparisons";
    },
  },

  methods: {
    downloadData: function(experimentId) {
      return util.loadFile("experiments/" + experimentId + "/data");
    },
  },

  created() {
    this.experiment = this.getExperiment(this.id);
    if (this.experiment === undefined) {
      this.$router.push({ name: "Home" });
    }
    this.comparisons = this.getComparisons(this.id);
  },
};
</script>

<style scoped></style>
