import mixins from "../mixins";

const state = () => ({
  ...mixins.getDefaultState(),
});

const getters = {
  ...mixins.getDefaultGetters(),
  ...mixins.getCodeGetters(),

  getName: (state, getters) => (key) => {
    return getters.getPropertyValue(key, "name");
  },

  getDisplayName: (state, getters) => (key) => {
    return getters.getName(key) || key;
  },

  filterByVocabulary: (state, getters, rootState, rootGetters) => (key) => {
    const id = Number.isInteger(key) ? key : rootGetters["vocabulary/getId"](key);
    return getters.filterBy("vocabulary_id", id);
  },

  filterByDomain: (state, getters, rootState, rootGetters) => (key) => {
    const id = Number.isInteger(key) ? key : rootGetters["domain/getId"](key);
    return getters.filterBy("domain_id", id);
  },
};

const mutations = {
  ...mixins.getDefaultMutations(),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
