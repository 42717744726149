<template>
  <div></div>
</template>

<script>
export default {
  name: "DownloadJsonCsv",

  props: {
    fileType: {
      type: String,
      required: true,
      default: "",
    },
    fields: {
      type: Array,
      required: false,
      default: () => [],
    },
    fileName: {
      type: String,
      required: false,
      default: "data",
    },
    confirm: {
      type: String,
      required: false,
      default: null,
    },
  },

  methods: {
    handleDownload(jsonData) {
      let content = this.createContent(jsonData);

      if (content === null) {
        this.$emit("error");
        return;
      }
      if (this.confirm !== null) {
        const result = confirm(this.confirm);
        if (!result) return;
      }
      const blob = new Blob([content], {
        type: `application/${this.fileType}`,
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${this.fileName}.${this.fileType}`;
      link.click();
      this.$emit("success");
    },

    createContent(jsonData) {
      let content = null;

      if (this.fileType === "json") {
        content = JSON.stringify(jsonData);
      } else if (this.fileType === "csv") {
        const keys =
          this.fields.length > 0 ? this.fields : Object.keys(jsonData[0]);
        let csv = `\ufeff${keys.join()}\n`;
        for (let index = 0; index < jsonData.length; index++) {
          const item = jsonData[index];
          let line = keys
            .map((key) => {
              if (item[key] === null) {
                return null;
              } else if (typeof item[key] === "object") {
                return JSON.stringify([item[key]]);
              } else {
                return [item[key]];
              }
            })
            .join(",");
          csv += `${line}\n`;
        }
        content = csv;
      }
      return content;
    },
  },
};
</script>

<style scoped></style>
