import mixins from "../mixins";

const state = () => ({
  ...mixins.getDefaultState(),
});

const getters = {
  ...mixins.getDefaultGetters(),
  ...mixins.getCodeGetters(),

  getName: (state, getters) => (key) => {
    return getters.getPropertyValue(key, "name");
  },
};

const mutations = {
  ...mixins.getDefaultMutations(),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
