<template>
  <v-card flat class="transparent">
    <v-card-title class="justify-center">EPDC in numbers:</v-card-title>
    <v-card-subtitle class="d-flex justify-center"
      >Last update: 02.10.2023</v-card-subtitle
    >
    <v-container fluid>
      <v-row>
        <v-col v-for="(metric, index) in metrics" :key="index">
          <v-card flat class="transparent">
            <div class="d-flex justify-center">
              <router-link :to="metric.url">
                <v-btn icon>
                  <v-icon x-large>{{ metric.icon }}</v-icon>
                </v-btn>
              </router-link>
            </div>
            <v-card-text
              class="d-flex justify-center text-uppercase font-weight-bold"
              >{{ metric.text }}</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Metrics",

  data: () => ({}),
  computed: {
    ...mapGetters({
      getProjectCount: "project/count",
      getStudyCount: "study/count",
      getExperimentCount: "experiment/count",
      getComparisonCount: "comparison/count",
    }),
    metrics() {
      return [
        {
          text: this.getProjectCount + " projects",
          icon: "mdi-briefcase",
          url: "/projects",
        },
        {
          text: this.getStudyCount + " studies",
          icon: "mdi-file-multiple",
          url: "/studies",
        },
        {
          text: this.getExperimentCount + " experiments",
          icon: "mdi-test-tube",
          url: "/experiments",
        },
        {
          text: this.getComparisonCount + " comparisons",
          icon: "mdi-compare-horizontal",
          url: "/comparisons",
        },
      ];
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
