<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card flat>
            <v-card-title>
              Comparison
            </v-card-title>
            <v-card-text>
              {{ comparison.name }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- study -->
      <v-row>
        <v-col>
          <v-card flat>
            <v-card-title>
              Experiment
              <v-spacer></v-spacer>
              <v-icon v-if="$keycloak.authenticated && comparison.study.is_public" @click="downloadData(experiment.id)"
                >mdi-download</v-icon
              >
            </v-card-title>
            <v-card-text>
              <p>
                <router-link :to="'/experiment/' + experiment.id">
                  {{ experiment.code }}
                </router-link>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card flat>
            <v-card-title>
              Statistical test
            </v-card-title>
            <v-card-text>
              <p>
                {{ getConceptName(comparison.test_concept_id) }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- hide/show -->
        <v-col class="d-flex justify-end align-center" v-if="resultCount">
          <v-btn dark @click="showResults = !showResults">
            {{ buttonLabel }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- data -->
      <v-row v-if="showResults">
        <v-col>
          <v-card flat v-if="comparison.study.is_public || $keycloak.authenticated">
            <v-card-title> {{ resultCount || "" }} Results </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="results"
                :sort-by.sync="sortBy"
                :options.sync="options"
                :server-items-length="resultCount"
                :loading="loading"
                class="elevation-1"
                v-if="resultCount"
              ></v-data-table>
            </v-card-text>
          </v-card>
          <v-card flat v-else>
            <v-card-title>Results</v-card-title>
            <v-card-text>
              <v-alert dense outlined type="warning">You must be authenticated to see the results.</v-alert>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import util from "../helpers/utilities.js";
import { mapGetters } from "vuex";
import { BASE_API_URL } from "@/app.config";
import http from "@/router/http";

function getSortOrder(options = {}) {
  if (!options.sortBy || !options.sortDesc) {
    return null;
  }
  const sortOrder = [];
  options.sortBy.forEach((field, index) => {
    sortOrder.push((options.sortDesc[index] ? "-" : "+") + field);
  });
  return sortOrder.join(",");
}

export default {
  name: "Comparison",

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      BASE_API_URL,
      experiment: null,
      comparison: null,
      showResults: true,
      page: 1,
      pageCount: 1,
      resultCount: 0,
      itemsPerPage: 10,
      sortBy: "p_value",
      options: {},
      loading: true,
      headers: [
        { text: "Analyte ID", value: "analyte_code" },
        { text: "Analyte Symbol", value: "analyte_symbol" },
        { text: "Analyte Name", value: "analyte_name" },
        { text: "P-value", value: "p_value" },
        { text: "Adj. P-value", value: "adjusted_p_value" },
        { text: "Effect Size", value: "effect_size" },
      ],
      results: [],
    };
  },

  methods: {
    downloadData: function(experimentId) {
      return util.loadFile("experiments/" + experimentId + "/data");
    },
    loadResultCount() {
      return http
        .get("comparisons/all/results", {
          params: {
            comparison_ids: this.id,
            options: "exclude_data",
          },
        })
        .then((response) => response.data)
        .then(({ meta }) => {
          this.resultCount = meta.total_entries;
        });
    },
    loadResults() {
      this.loading = true;
      return http
        .get("comparisons/all/results", {
          params: {
            comparison_ids: this.id,
            expansions: "analyte_id",
            analyte_fields: "*",
            page: this.options.page,
            per_page: this.options.itemsPerPage,
            sort: getSortOrder(this.options),
            options: "hide_pagination",
          },
        })
        .then((response) => response.data)
        .then(({ meta, data, includes }) => {
          const fields = meta.fields;
          const analytes = new Map();
          const results = [];
          if (includes) {
            includes.analytes.forEach((analyte) => {
              analytes.set(analyte.id, analyte);
            });
          }
          data.forEach((values) => {
            const record = {};
            fields.forEach((field, i) => (record[field] = values[i]));
            if (record.analyte_id) {
              let analyte = analytes.get(record.analyte_id);
              record.analyte_code = analyte.code || "";
              record.analyte_symbol = analyte.symbol || "";
              record.analyte_name = analyte.name || "";
              record.p_value = util.formatSciNum(record.p_value);
              record.adjusted_p_value = util.formatSciNum(record.adjusted_p_value);
              record.effect_size = util.formatNumber(record.effect_size);
            }
            results.push(record);
          });

          this.results = results;
          this.loading = false;
        });
    },
  },

  watch: {
    options: {
      handler() {
        this.loadResults();
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters({
      getConceptName: "concept/getName",
      getComparison: "comparison/getExpanded",
    }),

    buttonLabel: function() {
      return (this.showResults ? "Hide" : "Show") + " results";
    },
  },

  created() {
    this.comparison = this.getComparison(this.id);
    if (this.comparison === undefined) {
      this.$router.push({ name: "Home" });
    }
    this.experiment = this.comparison.experiment;
    this.loadResultCount(this.id);
  },
};
</script>

<style scoped></style>
