import Vue from "vue";
import Vuex from "vuex";

import actions from "./actions";

import analyte from "./modules/analyte";
import comparison from "./modules/comparison";
import concept from "./modules/concept";
import domain from "./modules/domain";
import experiment from "./modules/experiment";
import observation from "./modules/observation";
import project from "./modules/project";
import study from "./modules/study";
import vocabulary from "./modules/vocabulary";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  actions,
  modules: {
    analyte,
    comparison,
    concept,
    domain,
    experiment,
    observation,
    project,
    study,
    vocabulary,
  },
  strict: debug,
});
