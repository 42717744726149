<template>
  <v-main>
    <v-container fluid class="pa-7">
      <v-row>
        <v-col>
          <h1 class="text-h6 mb-4">
            Studies
          </h1>
        </v-col>
        <v-col>
          <v-text-field
            v-model="table.search"
            class="ma-0 pa-0"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="table.columns"
        :items="studies"
        item-key="id"
        :item-class="getRowClass"
        :items-per-page="table.itemsPerPage"
        :search="table.search"
        :sort-by="table.sortBy"
      >
        <template #[`item.code`]="{ item }">
          <router-link :to="'/study/' + item.id">{{ item.code }}</router-link>
        </template>
        <template #[`item.description`]="{ item }">
          <span v-html="item.description"></span>
        </template>
        <template #[`item.project.name`]="{ item }">
          <router-link :to="'/project/' + item.project.id">{{ item.project.name }}</router-link>
        </template>
        <template #[`footer.prepend`]>
          <vue-json-to-csv :json-data="studies" :labels="file.fields" :csv-title="file.name">
            <v-btn outlined color="indigo">
              <v-icon>mdi-download</v-icon>
              Export
            </v-btn>
          </vue-json-to-csv>
        </template>
      </v-data-table>
    </v-container>
  </v-main>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
import { mapGetters } from "vuex";

export default {
  name: "Studies",

  components: {
    VueJsonToCsv,
  },

  data() {
    return {
      file: {
        name: "studies",
        fields: {
          code: { title: "Code" },
          name: { title: "Name" },
          description: { title: "Description" },
          species_name: { title: "Species" },
          experiment_count: { title: "Experiments" },
          is_public: { title: "Public" },
          project_name: { title: "Project" },
        },
      },
      table: {
        columns: [
          {
            text: "Code",
            value: "code",
            width: "7%",
          },
          {
            text: "Name",
            value: "name",
          },
          {
            text: "Description",
            value: "description",
            width: "50%",
          },
          {
            text: "Species",
            value: "species_name",
            width: "7%",
          },
          {
            text: "Experiments",
            value: "experiment_count",
            width: "10%",
            align: "center",
          },
          {
            text: "Public",
            value: "is_public",
            width: "7%",
            align: "center",
          },
          {
            text: "Project",
            value: "project.name",
            width: "10%",
          },
        ],
        search: "",
        itemsPerPage: 10,
        sortBy: "code",
      },
    };
  },

  computed: {
    ...mapGetters({
      getStudies: "study/all",
      getExperiments: "experiment/all",
      getProject: "project/getById",
      getConceptName: "concept/getName",
    }),
    studies() {
      const studies = [];
      for (let study of this.getStudies) {
        const project = this.getProject(study.project_id);
        studies.push({
          ...study,
          project,
          is_public: study.is_public ? "yes" : "no",
          project_name: project.name,
          species_name: this.getConceptName(study.species_concept_id),
          experiment_count: this.getExperimentCount(study.id),
        });
      }
      return studies;
    },
    experimentCount() {
      const counts = new Map();
      for (let experiment of this.getExperiments) {
        let study_id = experiment.study_id;
        if (counts.has(study_id)) {
          counts.set(
            experiment.study_id,
            counts.get(study_id) + 1
          );
        } else {
          counts.set(study_id, 1);
        }
      }
      return counts;
    },
  },

  methods: {
    getExperimentCount(study_id) {
      return this.experimentCount.get(study_id) || 0;
    },
    getRowClass(item) {
      return !item.experiment_count ? "grey--text" : "";
    },
  },
};
</script>

<style scoped></style>
