<template>
  <div id="boxplotElement"></div>
</template>

<script>
import { prepareBoxplotData } from "echarts/extension/dataTool";
import * as echarts from "echarts";

export default {
  name: "BoxPlot",
  props: {
    axisData: Array,
    dataValues: Array,
  },
  data() {
    let boxplotData = prepareBoxplotData(this.dataValues);
    boxplotData.axisData = this.axisData;

    return {
      boxplotOptions: {
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "10%",
          right: "10%",
          bottom: "15%",
        },
        xAxis: {
          type: "category",
          data: boxplotData.axisData,
          boundaryGap: true,
          nameGap: 30,
          splitArea: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          name: "value",
          splitArea: {
            show: true,
          },
        },
        series: [
          {
            name: "boxplot",
            type: "boxplot",
            data: boxplotData.boxData,
            tooltip: {
              formatter: function(param) {
                return [
                  "upper: " + param.data[5],
                  "Q3: " + param.data[4],
                  "median: " + param.data[3],
                  "Q1: " + param.data[2],
                  "lower: " + param.data[1],
                ].join("<br/>");
              },
            },
          },
          {
            name: "outlier",
            type: "scatter",
            data: boxplotData.outliers,
          },
        ],
      },
    };
  },

  mounted: function() {
    let boxplotChart = echarts.init(document.getElementById("boxplotElement"));
    boxplotChart.setOption(this.boxplotOptions);
  },
};
</script>

<style lang="scss" scoped>
#boxplotElement {
  width: 600px;
  height: 400px;
}
</style>
