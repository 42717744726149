import Vue from "vue";
import axios from "axios";
import { BASE_API_URL } from "@/app.config";

const http = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

http.interceptors.request.use((config) => {
  const token = Vue.prototype.$keycloak.token;
  if (token) {
    config.headers.common['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

http.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status > 299) return Promise.reject(error.response.data);
    else return Promise.reject(error);
  }
);

export default http;
