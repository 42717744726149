<template>
  <v-card flat>
    <v-card-title>
      {{ currentTitle }}
    </v-card-title>

    <v-card-subtitle>
      {{ currentSubTitle }}
    </v-card-subtitle>
    
    <v-card-text>
      <v-form ref="signup" v-model="valid">
        <!-- name -->
        <v-text-field
          v-model="name"
          name="name"
          label="Name"
          :rules="textFieldRules"
          required
        >
        </v-text-field>

        <!-- email -->
        <v-text-field
          type="email"
          v-model="email"
          name="email"
          label="Email"
          :rules="emailRules"
          required
        >
        </v-text-field>

        <!-- company -->
        <v-text-field
          v-model="company"
          name="company"
          label="Company"
          :rules="textFieldRules"
          required
        >
        </v-text-field>

        <!-- project -->
        <v-select
          v-model="project"
          :items="projects"
          label="Project"
          :rules="projectRules"
          required
          multiple
          >
        </v-select>

        <!-- data use -->
        <v-textarea
          v-model="dataUse"
          name="dataUse"
          label="Data use"
          no-resize
          rows="4"
          :counter="maxTextArea"
          hint="A few words on how you plan to use the data"
          persistent-hint
          :rules="textAreaRules"
          required
        >
        </v-textarea>

        <!-- agreement -->
        <v-checkbox
          v-model="agreement"
          label="I agree to the conditions"
          :rules="agreementRules"
          required
        >
          <template v-slot:label>
            <div>
              Do you accept the terms and conditions
                <v-icon small class="pointer" @click.prevent="openLink('/terms')">mdi-open-in-new</v-icon>
                ?
            </div>
          </template>
        </v-checkbox>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>

        <div>
          <!-- cancel -->
          <v-btn 
            text
            @click.prevent="cancel()"
          >
            Cancel
          </v-btn>

          <!-- submit -->
          <v-btn 
            :disabled="!valid"
            color="success"
            @click.prevent="submit()"
          >
            Submit
          </v-btn>
        </div>
    </v-card-actions>
  </v-card>
</template>

<script>

import http from "@/router/http";

export default {
  name: "SignUpForm",

  data: () => ({
    currentTitle: "Please fill in the following form",
    currentSubTitle: "Account creation requires internal validation (all fields are required)",
    maxTextArea: 255,
    valid: false,
    name: null,
    email: null,
    company: null,
    project: null,
    dataUse: null,
    agreement: false,
    projects: [
      "RHAPSODY",
      "BEAt-DKD",
      "Other"
    ],
    emailRules: [
      v => !!v || 'Email is required',
      v =>  /.+@.+\..+/.test(v) || 'This does not look like an email'
    ],
    textFieldRules: [
      v => !!v || 'Field is required'
    ],
    projectRules: [
      v => !!v || 'Project is required'
    ],
    textAreaRules: [
      v => !!v || 'Field is required',
      v => (v && v.length <= 255) || 'Size limit exceeded'
    ],
    agreementRules: [
      v => !!v || 'Field is required'
    ],
    terms: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    conditions: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
  }),

  methods: {
    openLink(path) {
      const route = this.$router.resolve({ path: path });
      window.open(route.href, "_blank");
    },

    cancel() {
      this.$emit("signup-submitted", false);
    },

    submit() {
      const formData = {
        name: this.name,
        email: this.email,
        company: this.company,
        project: this.project,
        data_use: this.dataUse,
      };

      try {
        http.post('accounts/request', formData).then(() => {
          this.$emit("signup-submitted", true);
        });
      } catch (error) {
        console.error('An error has been encountered:', error);
      }
    }
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>