import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Documentation from "../views/Documentation";
import Results from "../views/Results";
import Project from "../views/Project";
import Study from "../views/Study";
import Experiment from "../views/Experiment";
import Comparison from "../views/Comparison";
import Projects from "../views/Projects";
import Studies from "../views/Studies";
import Experiments from "../views/Experiments";
import Comparisons from "../views/Comparisons";
import Downloads from "../views/Downloads";
import Credentials from "../views/Credentials";
import Private from "../views/Private";
import LoggedOut from "../views/LoggedOut";
import FederatedDatabases from "../views/FederatedDatabases";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/documentation",
    name: "Documentation",
    component: Documentation,
  },
  {
    path: "/results",
    name: "Results",
    component: Results,
  },
  {
    path: "/project/:id",
    name: "Project",
    component: Project,
    props: (route) => ({
      id: Number.parseInt(route.params.id, 10),
    }),
  },
  {
    path: "/study/:id",
    name: "Study",
    component: Study,
    props: (route) => ({
      id: Number.parseInt(route.params.id, 10),
    }),
  },
  {
    path: "/experiment/:id",
    name: "Experiment",
    component: Experiment,
    props: (route) => ({
      id: Number.parseInt(route.params.id, 10),
    }),
  },
  {
    path: "/comparison/:id",
    name: "Comparison",
    component: Comparison,
    props: (route) => ({
      id: Number.parseInt(route.params.id, 10),
    }),
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects,
  },
  {
    path: "/studies",
    name: "Studies",
    component: Studies,
  },
  {
    path: "/experiments",
    name: "Experiments",
    component: Experiments,
  },
  {
    path: "/comparisons",
    name: "Comparisons",
    component: Comparisons,
  },
  {
    path: "/downloads",
    name: "Downloads",
    component: Downloads,
  },
  {
    path: "/credentials",
    name: "Credentials",
    component: Credentials,
  },
  {
    path: "/private",
    name: "Private",
    component: Private,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/loggedout",
    name: "LoggedOut",
    component: LoggedOut,
  },
  {
    path: "/federated_databases",
    name: "FederatedDatabases",
    component: FederatedDatabases,
  },
  {
    // TODO redirect to 404 ?
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (router.app.$keycloak.authenticated) {
      next();
    } else {
      router.app.$keycloak.loginFn();
    }
  } else {
    next();
  }
});

export default router;
