<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col class="d-flex justify-center title">
          EPDC Documentation and Help
        </v-col>
      </v-row>
      <v-row>
        <!-- user guide -->
        <v-col>
          <v-card class="grey lighten-3" height="100%">
            <v-card-title class="d-flex justify-center"
              >User guide</v-card-title
            >
            <v-list class="transparent">
              <v-list-item
                v-for="(userGuide, index) in userGuides"
                :key="index"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    <v-btn text class="text-none">
                      {{ userGuide.text }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <!-- developer guide -->
        <v-col>
          <v-card class="grey lighten-5" height="100%">
            <v-card-title class="d-flex justify-center"
              >Developer guide</v-card-title
            >
            <v-list class="transparent">
              <v-list-item
                v-for="(developerGuide, index) in developerGuides"
                :key="index"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    <v-btn text class="text-none">
                      {{ developerGuide.text }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <!-- about data -->
        <v-col>
          <v-card class="grey lighten-3" height="100%">
            <v-card-title class="d-flex justify-center"
              >About EPDC data</v-card-title
            >
            <v-list class="transparent">
              <v-list-item
                v-for="(aboutDatum, index) in aboutData"
                :key="index"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    <v-btn text class="text-none">
                      {{ aboutDatum.text }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Documentation",

  components: {},

  data: () => ({
    userGuides: [
      {
        text: "Get started",
      },
      {
        text: "Search a gene",
      },
      {
        text: "Study view",
      },
      {
        text: "Experiment view",
      },
      {
        text: "Comparison view",
      },
      {
        text: "Export data",
      },
      {
        text: "FAQ",
      },
      {
        text: "How to cite us?",
      },
    ],
    developerGuides: [
      {
        text: "API documentation",
      },
      {
        text: "Download page",
      },
      {
        text: "Data model",
      },
      {
        text: "License, and term of use",
      },
    ],
    aboutData: [
      {
        text: "Rhapsody",
      },
      {
        text: "Beat-DKD",
      },
      {
        text: "IMIDIA",
      },
      {
        text: "HypoResolve",
      },
      {
        text: "Last update's facts sheet",
      },
    ],
  }),
};
</script>

<style scoped></style>
