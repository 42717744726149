<!-- requires login -->

<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-spacer></v-spacer>
        <v-col md="auto">
          <v-card>
            <v-card-text>
              This is a page that requires login
              <v-btn icon @click="loadUserProfile()">
                <v-icon>
                  mdi-reload
                </v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
          <v-alert type="success" v-if="profileSuccess">
            Profile
            <pre>
							{{ JSON.stringify(profileData, null, "\t") }}
						</pre
            >
            Info
            <pre>
							{{ JSON.stringify(infoData, null, "\t") }}
						</pre
            >
          </v-alert>
          <v-alert type="warning" v-else>
            No profile or failure while getting profile
          </v-alert>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import Vue from "vue";

export default {
  name: "Private",

  components: {},

  data() {
    return {
      profileData: null,
      infoData: null,
      profileSuccess: null,
    };
  },

  methods: {
    loadUserProfile() {
      let that = this;
      Vue.prototype.$keycloak
        .loadUserProfile()
        .success(function(profile) {
          Vue.prototype.$keycloak
            .loadUserInfo()
            .success(function(info) {
              that.profileData = profile;
              that.infoData = info;
              that.profileSuccess = true;
            })
            .error(function() {
              that.profileSuccess = false;
            });
        })
        .error(function() {
          that.profileSuccess = false;
        });
    },
  },
};
</script>

<style scoped></style>
