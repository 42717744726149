<template>
  <v-main>
    <v-container fluid class="pa-7">
      <v-row>
        <v-col>
          <h1 class="text-h6 mb-4">
            Comparisons
          </h1>
        </v-col>
        <v-col>
          <v-text-field
            v-model="table.search"
            class="ma-0 pa-0"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="table.columns"
        :items="comparisons"
        item-key="id"
        :items-per-page="table.itemsPerPage"
        :search="table.search"
        :sort-by="table.sortBy"
      >
        <template #[`item.name`]="{ item }">
          <router-link :to="'/comparison/' + item.id">{{ item.name }}</router-link>
        </template>
        <template #[`item.experiment.name`]="{ item }">
          <router-link :to="'/experiment/' + item.experiment.id">{{ item.experiment.name }}</router-link>
        </template>
        <template #[`item.study.name`]="{ item }">
          <router-link :to="'/study/' + item.study.id">{{ item.study.name }}</router-link>
        </template>
        <template #[`item.project.name`]="{ item }">
          <router-link :to="'/project/' + item.project.id">{{ item.project.name }}</router-link>
        </template>
        <template #[`footer.prepend`]>
          <vue-json-to-csv :json-data="comparisons" :labels="file.fields" :csv-title="file.name">
            <v-btn outlined color="indigo">
              <v-icon>mdi-download</v-icon>
              Export
            </v-btn>
          </vue-json-to-csv>
        </template>
      </v-data-table>
    </v-container>
  </v-main>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
import { mapGetters } from "vuex";

export default {
  name: "Comparisons",

  components: {
    VueJsonToCsv,
  },

  data() {
    return {
      file: {
        name: "comparisons",
        fields: {
          name: { title: "Name" },
          experiment_name: { title: "Experiment" },
          study_name: { title: "Study" },
          project_name: { title: "Project" },
        },
      },
      table: {
        columns: [
          {
            text: "Name",
            value: "name",
          },
          {
            text: "Experiment",
            value: "experiment.name",
          },
          {
            text: "Study",
            value: "study.name",
          },
          {
            text: "Project",
            value: "project.name",
          },
        ],
        search: "",
        itemsPerPage: 15,
        sortBy: "name",
      },
    };
  },

  computed: {
    ...mapGetters({
      getComparisons: "comparison/all",
      getExperiment: "experiment/getById",
      getStudy: "study/getById",
      getProject: "project/getById",
      getConceptName: "concept/getName",
    }),
    comparisons() {
      const comparisons = [];
      for (let comparison of this.getComparisons) {
        const experiment = this.getExperiment(comparison.experiment_id);
        const study = this.getStudy(experiment.study_id);
        const project = this.getProject(study.project_id);
        comparisons.push({
          ...comparison,
          experiment,
          study,
          project,
          experiment_name: experiment.name,
          study_name: study.name,
          project_name: project.name,
          test_name: this.getConceptName(comparison.test_concept_id),
        });
      }
      return comparisons;
    },
  },
};
</script>

<style scoped></style>
