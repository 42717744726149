<template>
  <v-main>
    <v-container fluid class="pa-7">
      <h1 class="text-h6 mb-4">
        Projects
      </h1>
      <v-container fluid>
        <v-layout row wrap>
          <v-card v-for="(project, index) in projects" :key="index" width="300" class="mr-4 mb-4">
            <v-card-title>
              <router-link class="text-no-wrap" :to="'/project/' + project.id">
                <v-img :src="`images/logos/${project.code}.jpg`" height="100" max-width="250" contain></v-img>
              </router-link>
            </v-card-title>
            <v-card-subtitle class="text-center mt-0">
              {{ project.description }}
            </v-card-subtitle>
          </v-card>
        </v-layout>
      </v-container>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Projects",

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      projectCount: "project/count",
      projects: "project/all",
    }),
  },
};
</script>

<style scoped></style>
