import mixins from "../mixins";

const state = () => ({
  ...mixins.getDefaultState(),
  activeId: null,
  /**
   * search strings that were not found after a search, reset to empty array by default
   */
  notFound: [],
});

const getters = {
  ...mixins.getDefaultGetters(),
  ...mixins.getCodeGetters(),

  ids: (state) => {
    return Array.from(state.entities.keys());
  },

  lastId: (state) => {
    return [...state.entities.keys()].pop();
  },

  typeIndex: (state) => {
    const index = new Map();
    state.entities.forEach((entity) => {
      const analyteIds = index.get(entity.analyte_concept_id);
      if (analyteIds) {
        analyteIds.push(entity);
      } else {
        index.set(entity.analyte_concept_id, [entity]);
      }
    });
    return index;
  },

  filterByType: (state, getters) => (typeId) => {
    return getters.typeIndex.get(typeId) || [];
  },

  getCode: (state, getters) => (key) => {
    return getters.getPropertyValue(key, "code");
  },

  getName: (state, getters) => (key) => {
    return getters.getPropertyValue(key, "name");
  },

  getActiveId: (state) => {
    return state.activeId;
  },

  getNotFound: (state) => {
    return state.notFound;
  },
};

const mutations = {
  ...mixins.getDefaultMutations(),

  setActive(state, id) {
    state.activeId = id;
  },

  setNotFound(state, notFound) {
    state.notFound = notFound;
  },
};

const actions = {
  setActiveId({ commit, getters }, id) {
    if (getters.exists(id)) {
      commit("setActive", id);
    }
  },

  remove({ dispatch, commit, getters }, id) {
    if (getters.exists(id)) {
      commit("delete", id);
      dispatch("comparison/removeResults", id, { root: true });
    }
  },

  removeAll({ dispatch, commit }) {
    commit("deleteAll");
    commit("setActive", null);
    dispatch("comparison/removeAllResults", null, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
