import http from "@/router/http";
import mixins from "../mixins";

const state = () => ({
  ...mixins.getDefaultState(),
  results: [],
});

const getters = {
  ...mixins.getDefaultGetters(),

  getResults: (state) => {
    return state.results;
  },

  getGroupedResults: (state) => {
    const results = new Map();
    state.results.forEach((result) => {
      const group = result.biospecimen_set_code;
      if (results.has(group)) {
        const values = results.get(group);
        values.push(result.value);
        results.set(group, values);
      } else {
        results.set(group, [result.value]);
      }
    });
    return results;
  },
};

const mutations = {
  ...mixins.getDefaultMutations(),

  insertResults(state, results) {
    state.results = [...state.results, ...results];
  },

  deleteAllResults(state) {
    state.results = [];
  },
};

const actions = {
  loadResults({ commit }, params) {
    return http
      .get("observations/all/results", {
        params: {
          analyte_ids: params.analyte_id,
          comparison_ids: params.comparison_id,
          expansions: "biospecimen_set_id",
          options: "disable_pagination",
        },
      })
      .then((response) => response.data)
      .then((payload) => {
        const results = [];
        if (payload.data.length) {
          const fields = payload.meta.fields;
          const biospecimenSets = new Map();
          payload.includes.biospecimen_sets.forEach((biospecimenSet) => {
            biospecimenSets.set(biospecimenSet.id, biospecimenSet);
          });
          payload.data.forEach((record) => {
            const values = {};
            record.forEach((value, index) => {
              values[fields[index]] = value;
            });
            const biospecimenSetId = values.biospecimen_set_id;
            values.biospecimen_set_code = biospecimenSets.get(biospecimenSetId).code;
            results.push(values);
          });
        }
        commit("deleteAllResults");
        commit("insertResults", results);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
