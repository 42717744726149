<template>
  <span>
    <v-dialog 
      v-model="dialog" 
      width="500"
      scrollable>
      <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!$keycloak.authenticated"
            text
            class="text-none"
            v-bind="attrs"
            v-on="on"
          >
            Sign up
          </v-btn>
      </template>

      <SignUpForm @signup-submitted="submitted" />
    </v-dialog>

    <v-snackbar 
      v-model="snackbar"
      :color="snackbarColor"
      >
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          @click="snackbar = false"
          >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </span>
</template>

<script>
import SignUpForm from "./SignUpForm";

export default {
  name: "SignUpDialog",

  components: {
    SignUpForm
  },

  data: () => ({
    dialog: false,
    snackbar: false,
    snackbarColor: null,
    snackbarMessage: null
  }),


  methods: {
    /**
     * @todo anything else ?
     * @param {*} status false = cancel or failure, true = submit successful
     */
    submitted(status) {
      this.dialog = false;
      this.snackbar = true;

      if(status) {
        this.snackbarColor = "success";
        this.snackbarMessage = "Request submitted";
      } else {
        this.snackbarColor = "error";
        this.snackbarMessage = "Request canceled";
      }
    }
  }
}
</script>