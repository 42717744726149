<template>
  <v-container>
    <!-- text -->
    <v-row>
      <v-col cols="6" class="offset-3 d-flex justify-center">
        <span class="display-1">Search for a biological entity</span>&nbsp;
      </v-col>
    </v-row>
    <!-- search field -->
    <v-row>
      <!-- cumulative search -->
      <v-col cols="2" class="offset-1">
        <v-switch v-model="isCumulativeSearch" label="Cumulative search"> </v-switch>
      </v-col>
      <v-col cols="6">
        <!-- multiple analytes -->
        <v-textarea
          v-if="isSearchList"
          v-model="searchListTerms"
          rows="3"
          outlined
          no-resize
          clearable
          solo
          :loading="isLoading"
          :hint="textareaHint"
          persistent-hint
        >
        </v-textarea>
        <!-- single analyte with auto-complete -->
        <v-autocomplete
          v-else
          ref="autocomplete"
          v-model="selectedGene"
          :items="genes"
          :loading="isLoading"
          :search-input.sync="searchTerm"
          hide-details
          hide-selected
          item-value="id"
          item-text="item"
          return-object
          solo
          @change="reset"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                Example:
                <ul class="suggested-terms">
                  <li v-for="term in suggestedTerms" :key="term.id" class="term">
                    <a href="#" @click.prevent="insertSuggestion($event)">{{ term.name }}</a>
                  </li>
                </ul>
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-html="highlightMatches(item.symbol)"></v-list-item-title>
              <v-list-item-subtitle v-html="highlightMatches(item.text)"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="indigo lighten-4">{{ getAnalyteIcon(item.species_concept_id) }}</v-icon>
            </v-list-item-action>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-switch v-model="isSearchList" label="Search a list" @change="changeSwitch()"> </v-switch>
        <v-btn depressed v-if="isSearchList" @click="getResultsForMultipleAnalytes()">
          Search
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import router from "../router";
import http from "@/router/http";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SearchBar",

  data: () => ({
    genes: [],
    isLoading: false,
    selectedGene: null,
    searchTerm: null,
    isSearchList: false,
    isCumulativeSearch: true,
    searchListTerms: null,
    suggestedTerms: [
      { id: 1, name: "Aldob" },
      { id: 2, name: "DPM1" },
      { id: 3, name: "ENSG00000143632" },
    ],
    debounce: 200,
    limit: 100,
  }),

  watch: {
    searchTerm(text) {
      // check to only search for matches if:
      // 1. there is a text
      // 2. text is longer then 2 characters
      // 3. it is not the autocomplete-item itself, which is put when an entry
      // was selected
      if (text !== null && text.length > 2 && !text.includes("ac-item")) {
        this.loadGenesDebounced(text);
      } else {
        this.genes = [];
      }
    },

    selectedGene(gene) {
      if (!gene) {
        this.genes = [];
      } else {
        this.getComparisonResults({
          analyte_id: this.selectedGene.id,
        });
      }
    },

    searchParams(params) {
      const analyteIds = this.analyteIds.join(",");
      if (analyteIds.length > 0) {
        this.loadComparisonResults({ ...params, analyte_id: analyteIds });
      }
    },
  },

  created: function() {
    this.loadGenesDebounced = _.debounce(this.loadGenes, this.debounce);
  },

  computed: {
    ...mapGetters({
      getConceptName: "concept/getName",
      analyteIds: "analyte/ids",
      searchParams: "comparison/searchParams",
    }),

    textareaHint: function() {
      return "enter a list of comma-separated identifiers, or one identifier per line (" + this.limit + " max)";
    },
  },

  methods: {
    ...mapActions({
      loadComparisonResults: "comparison/loadResults",
      removeAllAnalytes: "analyte/removeAll",
    }),

    insertSuggestion(event) {
      this.selectedGene = null;
      this.searchTerm = event.target.text;
    },

    highlightMatches(text) {
      if (!text) {
        return "";
      }
      const escapedTerm = _.escapeRegExp(this.searchTerm);
      const pattern = new RegExp(escapedTerm, "gi");
      return text.replace(pattern, (match) => {
        return "<strong>" + match + "</strong>";
      });
    },

    getAnalyteIcon(conceptId) {
      const speciesName = this.getConceptName(conceptId);
      switch (speciesName) {
        case "Human":
          return "mdi-human";
        case "Mouse":
          return "mdi-rodent";
        default:
          return "mdi-gene";
      }
    },

    loadGenes(searchTerm) {
      if (!this.isCumulativeSearch) {
        this.removeAllAnalytes();
      }

      http
        .get("analytes/search", {
          params: {
            term: searchTerm,
          },
        })
        .then((response) => response.data)
        .then((payload) => payload.data)
        .then((genes) => {
          genes = _.map(genes, (gene) => {
            gene.symbols = gene.symbol;
            gene.synonymList = "";
            if (gene.details && Array.isArray(gene.details.synonyms) && gene.details.synonyms.length) {
              gene.synonymList = gene.details.synonyms.join(", ");
              gene.symbols += ", " + gene.synonymList;
            }
            gene.item = "ac-item - " + gene.code + " - " + gene.symbol + " - " + gene.synonymList;
            gene.text = gene.synonymList + (gene.synonymList ? " " : "") + "(" + gene.code + ")";

            return gene;
          });
          this.genes = genes;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getResultsForMultipleAnalytes() {
      if (!this.searchListTerms) {
        return;
      }
      if (!this.isCumulativeSearch) {
        this.removeAllAnalytes();
      }
      let analyteKeys = this.searchListTerms.split(/[\r\n,]/);
      analyteKeys = analyteKeys.map((code) => code.trim());
      analyteKeys = analyteKeys.filter((code) => code.length > 0);
      analyteKeys = analyteKeys.slice(0, this.limit - 1);
      analyteKeys = [...new Set(analyteKeys)];
      analyteKeys.sort();
      if (analyteKeys) {
        this.getComparisonResults({
          analyte_keys: analyteKeys.join(","),
        });
        this.searchListTerms = null;
      }
    },

    getComparisonResults(params) {
      if (this.searchParams) {
        params = { ...params, ...this.searchParams };
      }
      this.loadComparisonResults(params);
      const path = "/results";
      if (this.$route.path !== path) {
        router.push(path);
      }
    },

    reset() {
      this.$nextTick(() => {
        this.selectedGene = null;

        if (this.$refs.autocomplete) {
          this.$refs.autocomplete.internalSearch = null;
        }
      });
    },

    changeSwitch() {
      this.searchListTerms = null;
    },
  },

  mounted() {
    const analytesSymbols = sessionStorage.getItem("analytesSymbols");
    
    // only load from session when on Results page
    if(this.$route.name === 'Results' && analytesSymbols && analytesSymbols.length > 0) {
      this.searchListTerms = analytesSymbols;
      this.getResultsForMultipleAnalytes();
    }
  }
};
</script>

<style lang="scss" scoped>
.suggested-terms {
  display: inline-block;
  padding-inline-start: 0;
  list-style: none;
  .term {
    display: inline-block;
  }
  .term ~ .term::before {
    content: ", ";
  }
}
</style>
