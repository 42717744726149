<template>
  <v-container fluid>
    <v-row>
      <!-- logo -->
      <v-col class="d-flex align-center justify-start">
        <router-link to="/">
          <img class="app-logo" src="images/logos/EPDC.png" />
        </router-link>
      </v-col>
      <v-spacer></v-spacer>
      <!-- links -->
      <v-col class="d-flex justify-end">
        <v-btn text to="/results" v-if="analyteCount && $route.name !== 'Results'">
          <v-icon>mdi-arrow-left</v-icon>
          Back to Search
        </v-btn>
        <v-btn text href="https://documenter.getpostman.com/view/1790903/UVJfhuva">API</v-btn>
        <v-btn
          v-for="(link, index) in links"
          :key="index"
          v-show="!link.hide"
          :to="link.to"
          class="text-none"
          text
        >
          {{ link.text }}
        </v-btn>
        <!-- email -->
        <EmailButton text="Contact us"/>
        <!-- sign up -->
        <SignUpDialog v-show="true" />
        <!-- not logged in -->
        <v-btn
          v-if="!$keycloak.authenticated"
          class="text-none"
          @click="$keycloak.loginFn"
        >
          Sign in
        </v-btn>
        <!-- logged in -->
        <v-menu v-else :open-on-hover="true" :offset-y="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="text-none" v-on="on" v-bind="attrs">
              <v-icon large>mdi-account</v-icon>
              <v-spacer></v-spacer>
              {{ $keycloak.userName }}
              <v-spacer></v-spacer>
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <!-- manage account -->
            <v-list-item @click="$keycloak.accountManagement">
              Account <v-spacer></v-spacer> <v-icon>mdi-account</v-icon>
            </v-list-item>
            <!-- logout -->
            <v-list-item @click="$keycloak.logoutFn">
              Sign out <v-spacer></v-spacer> <v-icon>mdi-logout</v-icon>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import EmailButton from '../components/EmailButton';
import SignUpDialog from "../components/SignUpDialog";

export default {
  name: "NavBar",

  components: {
    EmailButton,
    SignUpDialog
  },

  computed: {
    ...mapGetters({
      analyteCount: "analyte/count",
    }),
  },

  data: () => ({
    links: [
      {
        text: "Help",
        to: "",
        hide: true,
      },
      {
        text: "Downloads",
        to: "/downloads",
        hide: true,
      },
    ],
  })
};
</script>

<style scoped>
.app-logo {
  display: block;
  height: 36px;
}
</style>
