const mixins = {
  // State
  getDefaultState: () => {
    return {
      entities: new Map(),
    };
  },

  // Getters
  getDefaultGetters: () => {
    return {
      count: (state) => {
        return state.entities.size;
      },

      all: (state) => {
        return Array.from(state.entities.values());
      },

      exists: (state) => (id) => {
        return state.entities.has(id);
      },

      getById: (state) => (id) => {
        return state.entities.get(id);
      },

      get: (state, getters) => (id) => {
        return getters.getById(id);
      },

      getPropertyValue: (state, getters) => (id, propertyName) => {
        const entity = getters.get(id);
        return entity ? entity[propertyName] : undefined;
      },

      filterBy: (state, getters) => (propertyName, propertyValue) => {
        return getters.all.filter((entity) => {
          return entity[propertyName] === propertyValue;
        });
      },
    };
  },
  getCodeGetters: () => {
    return {
      codeIndex: (state) => {
        const index = new Map();
        state.entities.forEach((entity) => index.set(entity.code, entity));
        return index;
      },

      getByCode: (state, getters) => (code) => {
        return getters.codeIndex.get(code);
      },

      get: (state, getters) => (key) => {
        return Number.isInteger(key)
          ? getters.getById(key)
          : getters.getByCode(key);
      },

      getId: (state, getters) => (key) => {
        return getters.getPropertyValue(key, "id");
      },
    };
  },

  // Mutations
  getDefaultMutations: () => {
    return {
      insert(state, records) {
        const entities = new Map(state.entities);
        records.forEach((entity) => {
          if (!entities.has(entity.id)) {
            entities.set(entity.id, entity);
          }
        });
        state.entities = entities;
      },

      delete(state, id) {
        const entities = new Map(state.entities);
        entities.delete(id);
        state.entities = entities;
      },

      deleteAll(state) {
        state.entities = new Map();
      },
    };
  },
};

export default mixins;
