<template>
  <v-main>
    <v-container fluid>
      <!-- title -->
      <v-row>
        <v-col class="d-flex justify-center title">
          Download EPDC data
        </v-col>
      </v-row>
      <!-- files -->
      <v-row>
        <v-col>
          <v-treeview :items="downloads" selectable>
            <template v-slot:label="{ item }">
              {{ item.name }} <Tooltip v-if="item.tooltip" />
            </template>
          </v-treeview>
        </v-col>
        <v-col class="d-flex justify-end align-end">
          <v-btn dark>
            Download
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import Tooltip from "../components/Tooltip";

export default {
  name: "Downloads",

  components: {
    Tooltip,
  },

  data: () => ({
    downloads: [],
  }),
};
</script>

<style scoped></style>
