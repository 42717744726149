<template>
  <v-btn
    :href="'mailto:' + email"
    class="text-none"
    text
  >
    {{ computedText }}
  </v-btn>
</template>

<script>
export default {
  name: "EmailButton",

  props: {
    text: {
      type: String
    }
  },

  computed: {
    computedText: function() {
      if(this.text) {
        return this.text;
      }

      return this.email;
    }
  },

  data() {
    return {
      email: "epdc@sib.swiss"
    }
  }
};
</script>

<style scoped></style>
